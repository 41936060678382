import axios from 'axios'
import Cookies from 'js-cookie'

export const axiosWithAuth = () => {
  const token = Cookies.get('auth')
  const instance = axios.create({
    baseURL: 'https://agent-management.onrender.com',
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  })

  return instance
}

export const axiosWithoutAuth = () => {
  const instance = axios.create({
    baseURL: 'https://agent-management.onrender.com',
  })

  return instance
}
