import { useMutation } from '@tanstack/react-query'
import useAgentInfoStore from '../store/agentInfo'
import useAuthStore from '../store/auth'
import { axiosWithAuth } from '../utils/axios'
export const useCreateAgent = () => {
  const axios = axiosWithAuth()
  const { name, instruction, openMessage, knowledges, setSuccessModalOpen } =
    useAgentInfoStore()
  const createAgent = async () => {
    try {
      const data = {
        name,
        instruction,
        open_message: openMessage,
        knowledges,
      }
      const res = await axios.post('agent', data)
      setSuccessModalOpen(true)
      return res
    } catch (e) {
      return null
    } finally {
    }
  }

  const { mutate } = useMutation(createAgent)
  return { mutate }
}
