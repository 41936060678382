import React, { HTMLProps, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'

type Props = HTMLProps<HTMLInputElement>

const ChatBotEmailInput = ({
  placeholder,
  value,
  onFocus,
  onBlur,
  className,
  onChange,
  onSubmit,
  ...props
}: Props) => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 100,
        height: 64,
        paddingLeft: 24,
        paddingRight: 24,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e5e7eb'
      }}
    >
      <input
        style={{
          borderStyle: 'none',
          outline: '2px solid transparent',
          outlineOffset: 2,
          height: "100%",
          width: "100%",
          backgroundColor: "transparent",
          color: '#1f2937'
        }}
        placeholder={placeholder}
        onChange={(e) => {
          onChange?.(e)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            onSubmit?.(e)
          }
        }}
        value={value}
        autoComplete='off'
        enterKeyHint='send'
        {...props}
      />
    </div>
  )
}

export default ChatBotEmailInput
