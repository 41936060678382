import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../App'
import { axiosWithAuth } from '../utils/axios'
import { Feedback } from './useFeedbacks'

export const useIgnoreFeedback = () => {
  const axios = axiosWithAuth()
  const ignoreFeedback = async ({
    id,
    offset,
  }: {
    id: number
    offset: number
  }) => {
    try {
      const data = {
        id,
      }
      const res = await axios.post('feedback_ignore', data)
      /*
      queryClient.setQueryData(['feedbacks', offset], (data: any) => {
        console.log('old data:', data)
        const newData = data.feedbacks.map((feedback: Feedback) => {
          if (feedback.id === id) {
            return res.data.result
          } else {
            return feedback
          }
        })
        console.log('new data:', newData)
        return {
          count: data.count - 1,
          feedbacks: newData,
        }
      })
      */
      return res
    } catch (e) {
      return null
    } finally {
    }
  }

  const { mutateAsync } = useMutation(ignoreFeedback)
  return { mutateAsync }
}

export const useReplyFeedback = () => {
  const axios = axiosWithAuth()
  const ignoreFeedback = async ({
    id,
    reply,
    isCorrected,
    offset,
  }: {
    id: number
    reply: string
    isCorrected: boolean
    offset: number
  }) => {
    try {
      const data = {
        id,
        reply,
        is_corrected: isCorrected,
      }
      const res = await axios.post('feedback_reply', data)
      /*
      queryClient.setQueryData(['feedbacks', offset], (data: any) => {
        console.log('old data:', data)
        const newData = data.feedbacks.map((feedback: Feedback) => {
          if (feedback.id === id) {
            return res.data.result
          } else {
            return feedback
          }
        })
        console.log('new data:', newData)
        return {
          count: data.count - 1,
          feedbacks: newData,
        }
      })
      */
      return res
    } catch (e) {
      return null
    } finally {
    }
  }

  const { mutateAsync } = useMutation(ignoreFeedback)
  return { mutateAsync }
}
