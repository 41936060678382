import { useQuery } from '@tanstack/react-query'
import { start } from 'repl'
import {
  SummarySort,
  SummarySource,
  useSummaryFilterStore,
} from '../store/filter'
import { axiosWithAuth } from '../utils/axios'
export const useReport = () => {
  const axios = axiosWithAuth()
  const { timeRange, sortMethod, source, startAt, endAt } =
    useSummaryFilterStore()
  const getStoreName = (storeName: SummarySource) => {
    if (storeName === SummarySource.GOOGLE_MAP) {
      return 'google_map'
    } else if (storeName === SummarySource.YELP) {
      return 'yelp'
    }
    return 'google_map'
  }
  const getSortMethod = (sortMethod: SummarySort) => {
    if (sortMethod === SummarySort.MOST_REVIEW) {
      return 'most_reviews_first'
    } else if (sortMethod === SummarySort.HIGHEST_RATING) {
      return 'highest_rating_first'
    }
    return 'most_reviews_first'
  }

  return useQuery({
    queryKey: ['report', timeRange, sortMethod, source, startAt, endAt],
    queryFn: async () => {
      try {
        const {
          data: { result },
        } = await axios.post('feedback_bi/per_store_highlight', {
          social_app_name: getStoreName(source),
          store_name_list: null,
          start_at: startAt.toISOString(),
          end_at: endAt.toISOString(),
          rank: getSortMethod(sortMethod),
        })
        return result
      } catch (e: any) {
        return []
      }
    },
    refetchOnWindowFocus: false,
  })
}
