import { useMutation } from '@tanstack/react-query'
import qs from 'qs'
import useSignIn from 'react-auth-kit/hooks/useSignIn'
import { useNavigate } from 'react-router-dom'
import useAuthStore from '../store/auth'
import { axiosWithAuth } from '../utils/axios'

interface LoginRequest {
  username: string
  password: string
  isReviewManagement?: boolean
}

export const useLogin = () => {
  const axios = axiosWithAuth()
  const { setLoading, setErrorModalOpen } = useAuthStore()
  const signIn = useSignIn()
  const navigate = useNavigate()
  const login = async ({
    username,
    password,
    isReviewManagement = false,
  }: LoginRequest) => {
    setLoading(true)
    try {
      const res = await axios.post(
        'auth/login',
        qs.stringify({
          username,
          password,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      if (res.data?.access_token) {
        signIn({
          auth: {
            token: res.data?.access_token,
          },
        })
        navigate(
          isReviewManagement
            ? '/customer-review-management'
            : '/agents?first_screen=1',
        )
      } else {
        setErrorModalOpen(true)
      }
    } catch {
    } finally {
      setLoading(false)
    }
  }
  const { mutateAsync } = useMutation(login)
  return { mutateAsync }
}
