import classNames from 'classnames'
import { MoonLoader } from 'react-spinners'
import { DateRangePicker, Rate, SelectPicker, TagPicker } from 'rsuite'
import ReviewNavbar from '../components/ReviewNavbar'
import { useReport } from '../query/useReport'
import {
  FeedbackType,
  StoreSort,
  SummarySort,
  SummarySource,
  TimeRange,
  useStoreFilterStore,
  useSummaryFilterStore,
} from '../store/filter'
import { ReactComponent as Sort } from '../static/Sort.svg'
import { useEffect, useState } from 'react'
import { ReactComponent as Left } from '../static/Left.svg'
import { ReactComponent as Right } from '../static/Right.svg'
import { PAGE_LIMIT } from '../utils/const'
import { useStoreFeedback } from '../query/useStoreFeedback'
import { useParams } from 'react-router-dom'
import IconButton from '../components/IconButton'
import Tag from '../components/Tag'
import { ReactComponent as Selected } from '../static/Selected.svg'


function StoreReport() {
  const { storeName } = useParams()
  const [offset, setOffset] = useState(0)
  const { data: storeFeedbackData, isFetching, isLoading } = useStoreFeedback(offset, storeName ?? null)
  const today = new Date()
  const dayOfWeek = today.getDay()

  const daysToLastMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1) + 7
  const lastMonday = new Date(today)
  lastMonday.setDate(today.getDate() - daysToLastMonday)

  const lastSunday = new Date(lastMonday)
  lastSunday.setDate(lastMonday.getDate() + 6)
  const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
  const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)
  function formatDate(date: Date) {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
  }
  const {
    timeRange,
    setTimeRange,
    sortMethod,
    setSortMethod,
    source,
    setSource,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
    feedbackType,
    setFeedbackType
  } = useStoreFilterStore()
  useEffect(() => {
    if (timeRange === TimeRange.PAST_WEEK) {
      setStartAt(lastMonday)
      setEndAt(lastSunday)
    } else if (timeRange === TimeRange.PAST_MONTH) {
      setStartAt(lastMonth)
      setEndAt(endOfLastMonth)
    }
  }, [timeRange])
  return (
    <div className="flex justify-center pt-[100px] pb-5 h-full min-h-0">
      <ReviewNavbar />
      <div className="flex flex-col items-center w-[1024px] min-h-[800px] pb-5">
        <div className="flex items-center justify-between mb-10 w-full px-6">
          <div className="text-[40px] font-bold">{`${storeName} store performance`}</div>
        </div>
        <div className="flex flex-col rounded-3xl w-full border-[1px] border-solid border-gray-200 shrink min-h-0">
          <div className="statistical-report-filter flex items-start justify-between w-full p-6 border-b border-b-gray-200">
            <div className="flex items-start gap-4">
              <div className="flex flex-col gap-2">
                <SelectPicker
                  value={timeRange}
                  onChange={(value) => {
                    if (value !== null) {
                      setTimeRange(value)
                    }
                  }}
                  placeholder="Time range"
                  cleanable={false}
                  data={[
                    {
                      label: `Past week (${formatDate(lastMonday)} - ${formatDate(lastSunday)})`,
                      value: TimeRange.PAST_WEEK,
                    },
                    {
                      label: `Past month (${formatDate(lastMonth)} - ${formatDate(endOfLastMonth)})`,
                      value: TimeRange.PAST_MONTH,
                    },
                    {
                      label: 'Choose date by yourself',
                      value: TimeRange.CUSTOM_DATE,
                    },
                  ]}
                  style={{ width: 260 }}
                  searchable={false}
                  menuClassName='popup'
                  renderMenuItem={(label, item) => {
                    return <div className='flex items-center gap-2.5'>
                      {
                        item.value === timeRange ? <Selected className='fill-blue-500' /> : <div className='h-6 w-6' />
                      }
                      {label}
                    </div>
                  }}
                />
                {timeRange === TimeRange.CUSTOM_DATE && (
                  <DateRangePicker
                    format="MM/dd/yyyy"
                    cleanable={false}
                    ranges={[]}
                    character=" – "
                    caretAs={null}
                    value={[startAt, endAt]}
                    onChange={(value) => {
                      if (value) {
                        setStartAt(value[0])
                        setEndAt(value[1])
                      }
                    }}
                  />
                )}
              </div>
              <SelectPicker
                value={source}
                onChange={(value) => {
                  if (value !== null) {
                    setSource(value)
                  }
                }}
                placeholder="Source"
                searchable={false}
                cleanable={false}
                data={[
                  {
                    label: 'Google Maps',
                    value: SummarySource.GOOGLE_MAP,
                  },
                ]}
                style={{ width: 160 }}
                menuClassName='popup'
                renderMenuItem={(label, item) => {
                  return <div className='flex items-center gap-2.5'>
                    {
                      item.value === source ? <Selected className='fill-blue-500' /> : <div className='h-6 w-6' />
                    }
                    {label}
                  </div>
                }}
              />
              <TagPicker
                value={feedbackType}
                onChange={(value) => {
                  if (value !== null) {
                    setFeedbackType(value)
                  }
                }}
                placeholder="Source"
                searchable={false}
                cleanable={false}
                data={[
                  {
                    label: 'Positive',
                    value: FeedbackType.POSITIVE,
                  },
                  {
                    label: 'Negative',
                    value: FeedbackType.NEGATIVE,
                  },
                ]}
                style={{ width: 220 }}
                menuClassName='popup'
                tagProps={{
                  className: 'custom-tag',
                  as: 'div',
                  closable: false
                }}
              />
            </div>
            <SelectPicker
              value={sortMethod}
              onChange={(value) => {
                if (value !== null) {
                  setSortMethod(value)
                }
              }}
              placeholder="Sort"
              label={<Sort style={{ marginRight: 10 }} />}
              cleanable={false}
              data={[
                {
                  label: 'Latest reviews first',
                  value: StoreSort.LATEST_REVIEW,
                },
              ]}
              style={{ width: 240 }}
              searchable={false}
              menuClassName='popup'
              renderMenuItem={(label, item) => {
                return <div className='flex items-center gap-2.5'>
                  {
                    item.value === sortMethod ? <Selected className='fill-blue-500' /> : <div className='h-6 w-6' />
                  }
                  {label}
                </div>
              }}
            />
          </div>

          <div className="overflow-auto border-collapse w-full min-h-[320px]">
            {isFetching || isLoading ? (
              <div className="flex items-center justify-center w-full h-full">
                <MoonLoader size={24} />
              </div>
            ) : (
              storeFeedbackData?.feedbacks?.map((data: any, index: number) => {
                return (
                  <div
                    className={classNames(
                      'flex items-center gap-10 min-h-20 px-5 py-6 [&:not(:last-child)]:border-b border-b-gray-200',
                    )}
                  >
                    <div className='flex flex-col items-start gap-2'>
                      <div className='flex items-center gap-2'>
                        {data?.complaint_category?.map((category: any) => (
                          <Tag type='severe' content={category} size='small' />
                        ))}
                        {data?.compliment_category?.map((category: any) => (
                          <Tag type='positive' content={category} size='small' />
                        ))}
                      </div>
                      <div>{`${data?.customer_name}: ${data?.feedback_content}`}</div>
                    </div>
                  </div>
                )
              })
            )}
          </div>
          <div className="flex items-center justify-end w-full p-6 border-t border-t-gray-200">
            <div className="flex items-center gap-3">
              {storeFeedbackData && (!isFetching && !isLoading) && `${Math.min(storeFeedbackData.count, offset + 1)}  -  ${Math.min(offset + PAGE_LIMIT, storeFeedbackData?.count)} of ${storeFeedbackData?.count}`}
              <IconButton
                Icon={Left}
                disabled={offset === 0}
                onClick={() => {
                  setOffset((value) => value - PAGE_LIMIT)
                }}
              />
              <IconButton
                Icon={Right}
                disabled={!storeFeedbackData || offset + PAGE_LIMIT >= storeFeedbackData?.count}
                onClick={() => {
                  setOffset((value) => value + PAGE_LIMIT)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StoreReport
