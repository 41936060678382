import { useMutation, useQuery } from '@tanstack/react-query'
import { queryClient } from '../App'
import { axiosWithAuth } from '../utils/axios'

export type Question = {
  answer: string
  have_future_question: boolean
  id: string
  llm_type: string
  query: string
  skip_rewrite: boolean
  title_name: string
  is_generating?: boolean
}
export const useHouseReport = (agentId: string | undefined) => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['house_report', agentId],
    queryFn: async () => {
      try {
        const {
          data: { result },
        } = await axios.get(`realtor_agent/report/${agentId}`)
        return result.question_list.map((question: Question) => ({
          ...question,
          is_generating: false,
        }))
      } catch (e: any) {
        return []
      }
    },
    refetchOnWindowFocus: false,
  })
}

export const useRegenerateReportQuestion = () => {
  const axios = axiosWithAuth()
  const regenerateQuestion = async ({
    id,
    agentId,
  }: {
    agentId: string
    id: string
  }) => {
    try {
      const data = {
        id,
        agent_id: agentId,
      }
      const res = await axios.post(
        'realtor_agent/regenerate_single_question_by_id',
        data,
      )
      queryClient.setQueryData(['house_report', agentId], (data: any) => {
        return res.data.result.question_list.map((question: Question) => ({
          ...question,
          is_generating: false,
        }))
      })
      return res
    } catch (e) {
      return null
    } finally {
    }
  }

  const { mutateAsync } = useMutation(regenerateQuestion)
  return { mutateAsync }
}

export const usePublicHouseReport = (token: string) => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['public_report', token],
    queryFn: async () => {
      try {
        const {
          data: { result },
        } = await axios.get(`realtor_agent/public_report?token=${token}`)
        return result.question_list.map((question: Question) => ({
          ...question,
          is_generating: false,
        }))
      } catch (e: any) {
        return []
      }
    },
    refetchOnWindowFocus: false,
  })
}

export const useShareToken = (agentId: string | undefined) => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['report_token', agentId],
    queryFn: async () => {
      try {
        const {
          data: { result },
        } = await axios.post('agent/share', {
          agent_id: agentId,
          valid_duration: 604800,
        })
        return result.token
      } catch (e: any) {
        return ''
      }
    },
    refetchOnWindowFocus: false,
  })
}
