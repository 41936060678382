import { ReactComponent as ArrowDown } from '../static/ArrowDown.svg'

type Props = {
  content: string
}

const Select = (props: Props) => {
  const { content } = props
  return <div className="flex rounded-[60px] items-center gap-2 bg-blue-50 py-2 px-3" >
    <div>{content}</div>
    <ArrowDown />
  </div >
}

export default Select