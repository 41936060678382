import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import logoDark from '../../static/LogoDark.svg'
import logoLight from '../../static/LogoLight.svg'

function Navbar({
  isDark,
  contactPos,
}: {
  isDark: boolean
  contactPos: number
}) {
  return (
    <div
      style={{
        backgroundImage: isDark ? "url(https://servicegenie.ai/HeroBG.svg)" : undefined,
      }}
      className={classNames(
        'sticky flex items-center justify-between w-full py-8 px-[60px] top-0 min-w-[1000px]',
        {
          'bg-cover text-white bg-no-repeat bg-top': isDark,
          'bg-white text-black': !isDark,
        },
      )}
    >
      <div className="flex items-center gap-3">
        {isDark ? <img src={logoLight} /> : <img src={logoDark} />}
        <div className="text-[24px] font-bold">ServiceGenie</div>
      </div>
      <div
        className="text-[24px] font-bold cursor-pointer"
        onClick={() => {
          window.scrollTo({
            top: contactPos,
            behavior: 'smooth',
          })
        }}
      >
        Contact us
      </div>
    </div>
  )
}

export default Navbar
