import { useQuery } from '@tanstack/react-query'
import { axiosWithAuth } from '../utils/axios'

export const useConversationHistoryByAgent = (agentId?: string) => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['conversationHistoryByAgent', agentId?.toString()],
    queryFn: async () => {
      let id = agentId
      if (!agentId) {
        id = 'all'
      }
      try {
        const {
          data: { result },
        } = await axios.get(`conversations/${id}`)
        return result
      } catch (e: any) {
        return null
      }
    },
    refetchOnWindowFocus: false,
  })
}

export const useConversationHistoryById = (conversationId?: string) => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['conversationHistoryById', conversationId?.toString()],
    queryFn: async () => {
      if (!conversationId) {
        return null
      }
      try {
        const {
          data: { result },
        } = await axios.get(`conversation/${conversationId}`)
        return result
      } catch (e: any) {
        return null
      }
    },
    refetchOnWindowFocus: false,
  })
}
