import { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams, useSearchParams } from 'react-router-dom'
import { MoonLoader, SyncLoader } from 'react-spinners'
import remarkGfm from 'remark-gfm'
import { useToaster } from 'rsuite'
import { queryClient } from '../App'
import Button, { ButtonType } from '../components/Button'
import Navbar from '../components/Navbar'
import TextArea from '../components/TextArea'
import { Question, useHouseReport, usePublicHouseReport, useRegenerateReportQuestion } from '../query/useHouseReport'
import { Sentence, timeout } from './Test'
import agentAvatar from '../static/AgentAvatar.svg'
import customerAvatar from '../static/CustomerAvatar.svg'

function PublicReport() {
  const { token } = useParams()
  const { data } = usePublicHouseReport(token ?? '')
  const questionList = data ?? []
  const LinkRenderer = ({ node, ...props }: any) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const openMessage = searchParams.get('message')
  const agentName = searchParams.get('name')
  const [chatHistory, setChatHistory] = useState<Sentence[]>([])
  const [conversationId, setConversationId] = useState(crypto.randomUUID())
  const [instruction, setInstruction] = useState('')
  const scrollRef = useRef<HTMLDivElement>(null)
  const addToHistory = (content: Sentence[]) =>
    setChatHistory((history) => {
      return [...history, ...content]
    })
  useEffect(() => {
    if (data) {
      setChatHistory([{ role: 'ai', content: openMessage ?? '' }])
    }
  }, [data])

  const handleSubmit = async () => {
    if (scrollRef.current) {
      scrollRef.current.style.overflow = 'hidden'
    }
    const text = instruction.toString()
    setInstruction('')
    addToHistory([
      { role: 'human', content: text },
      { role: 'ai', content: '' },
    ])
    const response = await fetch(
      'https://agent-management.onrender.com/agent_chat_public',
      {
        method: 'POST',
        body: JSON.stringify({
          token: token,
          user_input: text,
          chat_history: chatHistory,
          conversation_id: conversationId,
          "llm_type": "gpt-4",
          "skip_rewrite": false
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        },
      },
    )
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
    const reader = response.body
      ?.pipeThrough(new TextDecoderStream())
      .getReader()
    if (reader) {
      while (true) {
        const { value, done } = await reader.read()
        if (done) break
        setChatHistory((history) => {
          console.log('check', value)
          const newHistory = [...history]
          if (process.env.NODE_ENV === 'development') {
            console.log('check')
            if (!newHistory[newHistory.length - 1].content.endsWith(value)) {
              newHistory[newHistory.length - 1].content += value
            }
          } else {
            newHistory[newHistory.length - 1].content += value
          }
          return newHistory
        })
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        }
        await timeout(100)
      }
    }
    if (scrollRef.current) {
      scrollRef.current.style.overflow = 'auto'
    }
  }


  return (
    <div className="flex w-full h-screen">
      <Navbar isPublic />
      <div className="w-2/3 h-full overflow-scroll border-r border-solid">
        <div className="flex flex-col gap-8 p-10 pt-[120px]">
          {questionList?.map((question: Question) => {
            if (question.have_future_question) {
              return null
            }
            if (question.is_generating) {
              return <div className='h-[76px] flex items-center justify-center'><MoonLoader size={16} /></div>
            } else {
              return <div className="flex flex-col gap-3">
                <div className="flex justify-between">
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      a: LinkRenderer,
                    }}
                  >{question.title_name}</ReactMarkdown>
                </div>
                {question.query !== '' ? (
                  <ReactMarkdown
                    remarkPlugins={[remarkGfm]}
                    components={{
                      a: LinkRenderer,
                    }}
                  >{question.answer}</ReactMarkdown>
                ) : (
                  <></>
                )}
              </div>
            }
          })}
        </div>
      </div>
      <div ref={scrollRef} className="w-1/3 h-full overflow-scroll">
        <div className="flex pt-[120px] items-center justify-between mb-5 w-full px-10">
          {data && (
            <div className="text-[20px] font-bold">{`${agentName ?? ''}`}</div>
          )}
        </div>
        <div className="flex flex-col gap-6 w-full pt-0 pb-[150px] p-10">
          {chatHistory.map((message: any) => {
            if (message.role !== 'human' && message.role != 'ai') {
              return null
            }
            if (message.role === 'ai') {
              return (
                <div className="flex items-start w-full gap-3">
                  <img src={agentAvatar} />
                  <div className="px-3 py-2 rounded-2xl text-base max-w-[750px] bg-gray-50 conversation">
                    {message.content ? (
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: LinkRenderer,
                        }}
                      >
                        {message.content}
                      </ReactMarkdown>
                    ) : (
                      <SyncLoader size={8} />
                    )}
                  </div>
                </div>
              )
            } else {
              return (
                <div className="flex items-start w-full gap-3 flex-row-reverse">
                  <img src={customerAvatar} />
                  <div className="px-3 py-2 rounded-2xl text-base max-w-[750px] bg-gray-50 conversation">
                    {message.content ? (
                      <ReactMarkdown
                        components={{
                          a: LinkRenderer,
                        }}
                      >
                        {message.content}
                      </ReactMarkdown>
                    ) : (
                      <SyncLoader size={8} />
                    )}
                  </div>
                </div>
              )
            }
          })}
        </div>
        <div className="fixed flex items-center justify-center w-1/3 py-6 px-10 bottom-0 gap-4 border-t border-t-gray-200 bg-white">
          <div className="grow">
            <TextArea
              rows={1}
              height={24}
              value={instruction}
              isError={false}
              onChange={(e) => {
                setInstruction((e.target as HTMLInputElement).value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleSubmit()
                }
              }}
              fixHeight={false}
              maxHeight={96}
            />
          </div>
          <Button
            type={ButtonType.PRIMARY}
            content="Send"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div >
  )
}

export default PublicReport
