import classNames from "classnames"

export type Props = {
  type: 'normal' | 'positive' | 'mild' | 'severe'
  content?: string
  size?: 'small' | 'normal'
}

const Tag = (props: Props) => {
  const { type, content, size = 'normal' } = props
  if (type === 'positive') {
    return <div className={classNames("px-4 py-1 rounded-[20px] bg-green-600 text-white", {
      'text-xs': size === 'small'
    })}>{content ?? 'Positive'}</div>
  }
  if (type === 'mild') {
    return <div className={classNames("px-4 py-1 rounded-[20px] bg-yellow-500 text-white", {
      'text-xs': size === 'small'
    })}>{content ?? 'Mild negative'}</div>
  }
  if (type === 'severe') {
    return <div className={classNames("px-4 py-1 rounded-[20px] bg-red-700 text-white", {
      'text-xs': size === 'small'
    })}>{content ?? 'Severe negative'}</div>
  }
  return <div className="px-4 py-1 rounded-[20px] bg-blue-50">{content}</div>
}

export default Tag