import create from 'zustand'

type AuthStore = {
  loading: boolean
  setLoading: (value: boolean) => void
  successModalOpen: boolean
  setSuccessModalOpen: (value: boolean) => void
  errorModalOpen: boolean
  setErrorModalOpen: (value: boolean) => void
}

const useAuthStore = create<AuthStore>((set) => ({
  loading: false,
  setLoading: (value: boolean) => set({ loading: value }),
  successModalOpen: false,
  setSuccessModalOpen: (value: boolean) => set({ successModalOpen: value }),
  errorModalOpen: false,
  setErrorModalOpen: (value: boolean) => set({ errorModalOpen: value }),
}))

export default useAuthStore
