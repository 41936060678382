import { useQuery } from '@tanstack/react-query'
import { axiosWithAuth } from '../utils/axios'
import { PAGE_LIMIT } from '../utils/const'

export type Feedback = {
  id: number
  social_app_name: string
  store_name: string
  customer_name: string
  feedback_content: string
  star: string
  feedback_type: 'Negative' | 'Positive'
  complaint_category: string[]
  complaint_severity: 'Mild' | 'Serious'
  status: 'Generated' | 'Ignore' | 'Reply'
  generated_response: string
  corrected_response: string | null
  is_corrected: boolean
  generated_at: string
  feedback_created_at: string
  created_at: string
}

export const useFeedbacks = (
  offset: number,
  isBackgroundRefetch: boolean,
  setIsBackgroundRefetch: any,
) => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['feedbacks', offset],
    queryFn: async () => {
      try {
        const {
          data: { result },
        } = await axios.post('feedbacks', {
          offset,
          limit: PAGE_LIMIT,
        })
        return result
      } catch (e: any) {
        return []
      }
    },
    refetchOnWindowFocus: false,
    onSuccess: () => {
      if (isBackgroundRefetch) {
        console.log('This is a background refetch')
        setIsBackgroundRefetch(false)
      } else {
        console.log('This is a manual refetch')
      }
    },
  })
}
