import Button, { ButtonType } from './Button'
import { ReactComponent as Add } from '../static/Add.svg'
import { ReactComponent as Agent } from '../static/Agent.svg'
import { ReactComponent as Callhistory } from '../static/Callhistory.svg'
import { useNavigate } from 'react-router-dom'
import { useConversationHistoryByAgent } from '../query/useConversationHistory'

const Navbar = ({ isPublic = false }: { isPublic?: boolean }) => {
  const navigate = useNavigate()
  const { data, isFetching, isLoading, isFetched } = useConversationHistoryByAgent()
  return (
    <div className="fixed flex items-center justify-center w-full py-6 top-0 border-b border-b-gray-200 bg-white">
      <div className="flex w-[1024px] items-center justify-between">
        <div className="text-xl font-bold">ServiceGenie</div>
        {isPublic ? <></> : <div className="flex gap-4">
          {isFetched && data.length > 0 && <Button
            type={
              location.pathname === '/history'
                ? ButtonType.SELECTED
                : ButtonType.SECONDARY
            }
            Icon={Callhistory}
            content="Chat history"
            onClick={() => {
              navigate('/history')
            }}
          />}
          <Button
            type={
              location.pathname === '/agents'
                ? ButtonType.SELECTED
                : ButtonType.SECONDARY
            }
            Icon={Agent}
            content="All agents"
            onClick={() => { navigate('/agents') }}
          />
          <Button
            type={
              location.pathname === '/create'
                ? ButtonType.SELECTED
                : ButtonType.SECONDARY
            }
            Icon={Add}
            content="Create agent"
            onClick={() => { navigate('/create') }}
          />
        </div>
        }
      </div>
    </div>
  )
}

export default Navbar
