import { Route, Routes } from 'react-router-dom'
import RequireAuth from '@auth-kit/react-router/RequireAuth'
import CreateAgent from './pages/CreateAgent'
import Login from './pages/Login'
import Register from './pages/Register'
import Home from './pages/Home'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import AgentDetail from './pages/AgentDetail'
import ConversationHistory from './pages/ConversationHistory'
import History from './pages/History'
import Test from './pages/Test'
import ReplyCustomerReview from './pages/ReplyCustomerReview'
import ReviewManagementLogin from './pages/ReviewManagementLogin'
import StatisticalReport from './pages/StatisticalReport'
import 'rsuite/dist/rsuite-no-reset.min.css';
import StoreReport from './pages/StoreReport'
import Website from './website/Website'
import HouseReport from './pages/HouseReport'
import PublicReport from './pages/PublicReport'
import TestChatbot from './pages/TestChatbot'

export const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path="/" element={<Website />}></Route>
        <Route
          path="/agents"
          element={
            <RequireAuth fallbackPath={'/login'}>
              <Home />
            </RequireAuth>
          }
        ></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/review-management-login" element={<ReviewManagementLogin />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route
          path="/create"
          element={
            <RequireAuth fallbackPath={'/login'}>
              <CreateAgent />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/history/:agentId?"
          element={
            <RequireAuth fallbackPath={'/login'}>
              <History />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="/agent/:agentId"
          element={
            <RequireAuth fallbackPath={'/login'}>
              <AgentDetail />
            </RequireAuth>
          }
        />
        <Route
          path="/test/:agentId"
          element={
            <RequireAuth fallbackPath={'/login'}>
              <Test />
            </RequireAuth>
          }
        />
        <Route
          path="/conversation/:conversationId"
          element={
            <RequireAuth fallbackPath={'/login'}>
              <ConversationHistory />
            </RequireAuth>
          }
        />
        <Route
          path="/customer-review-management"
          element={
            <RequireAuth fallbackPath={'/review-management-login'}>
              <ReplyCustomerReview />
            </RequireAuth>
          }
        />
        <Route
          path="/statistical-report"
          element={
            <RequireAuth fallbackPath={'/review-management-login'}>
              <StatisticalReport />
            </RequireAuth>
          }
        />
        <Route
          path="/store-report/:storeName"
          element={
            <RequireAuth fallbackPath={'/review-management-login'}>
              <StoreReport />
            </RequireAuth>
          }
        />
        <Route
          path="/house-report/:agentId"
          element={
            <RequireAuth fallbackPath={'/login'}>
              <HouseReport />
            </RequireAuth>
          }
        />
        <Route
          path="/public-report/:token"
          element={
            <PublicReport />
          }
        />
        <Route
          path="/test-chatbot"
          element={
            <TestChatbot />
          }
        />
      </Routes>
    </QueryClientProvider>
  )
}

export default App
