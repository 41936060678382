import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import useAgentInfoStore from '../store/agentInfo'
import { axiosWithAuth } from '../utils/axios'

export const useUploadFile = () => {
  const axiosIns = axiosWithAuth()
  const { addToKnowledge } = useAgentInfoStore()
  const upload = async (file: File) => {
    try {
      const {
        data: { result },
      } = await axiosIns.get('knowledge_upload_gcs_url')
      const putUrl = result.upload_url
      const key = result.blob_name
      addToKnowledge(file.name, key)
      await axios.put(putUrl, file, {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      })
    } catch {}
  }
  const { mutateAsync } = useMutation(upload)
  return { mutateAsync }
}
