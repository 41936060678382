import Button, { ButtonType } from './Button'
import { ReactComponent as Reply } from '../static/Reply.svg'
import { ReactComponent as Alert } from '../static/Alert.svg'
import { ReactComponent as Stats } from '../static/Stats.svg'
import { useNavigate } from 'react-router-dom'

const ReviewNavbar = () => {
  const navigate = useNavigate()
  return (
    <div className="fixed flex items-center justify-center w-full py-6 top-0 border-b border-b-gray-200 bg-white z-50">
      <div className="flex w-[1024px] items-center justify-between">
        <div className="text-xl font-bold">Customer Review Management</div>
        <div className="flex gap-4">
          <Button
            type={
              location.pathname === '/customer-review-management'
                ? ButtonType.SELECTED
                : ButtonType.SECONDARY
            }
            Icon={Reply}
            content="Reply customer review"
            onClick={() => {
              navigate('/customer-review-management')
            }}
          />
          <Button
            type={
              location.pathname === '/statistical-report'
                ? ButtonType.SELECTED
                : ButtonType.SECONDARY
            }
            Icon={Stats}
            content="Statistical report"
            onClick={() => {
              navigate('/statistical-report')
            }}
          />
          <Button
            type={ButtonType.SECONDARY}
            Icon={Alert}
            content="Set alert"
            onClick={() => { }}
          />
        </div>
      </div>
    </div>
  )
}

export default ReviewNavbar
