import { useEffect, useRef, useState } from 'react'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { BeatLoader } from 'react-spinners'
import remarkGfm from 'remark-gfm'
import classNames from 'classnames'
import { Rnd } from 'react-rnd'
import ChatBotEmailInput from '../components/ChatBotEmailInput'
import ChatBotInput from '../components/ChatBotInput'
import useVisualViewport from './useViewport'
type Props = {
  apiKey: string
  bottom?: number
  right?: number
  shouldShowWelcomeMessage?: boolean
}

type ChatSentence = {
  role: 'human' | 'ai'
  content: string
  finished: boolean
  backgroundUpdated: boolean
}

const DURATION = 500

const exampleQuestions = [
  'Where is my order?',
  'How to start a custom?',
  'When can I expect my repair?',
  'How do you grow your lab diamonds?',
  'What is the difference between GHI and DEF moissanite?',
]

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const ChatBot = ({ apiKey, bottom = 80, right = 80, shouldShowWelcomeMessage = true }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [width, setWidth] = useState(480)
  const [height, setHeight] = useState(Math.max(660, window.innerHeight - 300))
  const [data, setData] = useState<any>(null)
  const [chatHistory, setChatHistory] = useState<ChatSentence[]>([])
  const [conversationId, setConversationId] = useState(crypto.randomUUID())
  const [instruction, setInstruction] = useState('')
  const [showWelcome, setShowWelcome] = useState(true)
  const [emailConfirmed, setEmailConfirmed] = useState(false)
  const [showEmailError, setShowEmailError] = useState(false)
  const windowRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [messageRead, setMessageRead] = useState(false)
  const isOpenRef = useRef<boolean>(false)
  const screenHeight = window.screen.height
  const screenWidth = window.screen.width
  const isMobile = screenWidth <= 414
  const viewport = useVisualViewport()
  console.log(isMobile)
  const addToHistory = (content: ChatSentence[]) =>
    setChatHistory((history) => {
      return [...history, ...content]
    })
  const getEmailFromCookie = () => {
    return localStorage.getItem('servicegenie-email')
  }
  const [email, setEmail] = useState(getEmailFromCookie() ?? '')
  useEffect(() => {
    fetch('https://agent-management.onrender.com/agent_by_api_key', {
      method: 'POST',
      body: JSON.stringify({
        api_key: apiKey,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.result)
      })
  }, [])
  useEffect(() => {
    if (data) {
      setChatHistory([
        {
          role: 'ai',
          content: data?.open_message,
          finished: true,
          backgroundUpdated: true,
        },
      ])
    }
  }, [data])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
      }
      setTimeout(() => {
        if (!chatHistory) return
        setChatHistory((history) =>
          history.map((sentence) => ({
            ...sentence,
            backgroundUpdated: false,
          })),
        )
      }, 5000)
    } else {
      document.body.style.overflow = ''
    }
  }, [isOpen])

  const LinkRenderer = ({ node, ...props }: any) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )

  const readHistory = chatHistory.filter(
    (sentence) => !sentence.backgroundUpdated,
  )

  const unreadHistory = chatHistory.filter(
    (sentence) => sentence.backgroundUpdated,
  )

  const handleSubmit = async (question?: string) => {
    if (chatHistory[chatHistory.length - 1].backgroundUpdated) {
      setChatHistory((history) => {
        const newHistory = [...history]
        newHistory[newHistory.length - 1].backgroundUpdated = false
        return newHistory
      })
    }
    //if (scrollRef.current) {
    //  scrollRef.current.style.overflow = 'hidden'
    //}
    const text = question ? question : instruction.toString()
    let needScroll = false
    if (scrollRef.current) {
      if (
        Math.abs(
          scrollRef.current.scrollHeight -
          scrollRef.current.clientHeight -
          scrollRef.current.scrollTop,
        ) <= 1
      ) {
        needScroll = true
      }
    }
    setInstruction('')
    addToHistory([
      {
        role: 'human',
        content: text,
        finished: true,
        backgroundUpdated: false,
      },
      { role: 'ai', content: '', finished: false, backgroundUpdated: false },
    ])
    const response = await fetch(
      'https://agent-management.onrender.com/agent_chat_api_key',
      {
        method: 'POST',
        body: JSON.stringify({
          api_key: apiKey,
          user_input: text,
          chat_history: chatHistory.map((sentence) => {
            return {
              role: sentence.role,
              content: sentence.content,
            }
          }),
          conversation_id: conversationId,
          end_user_identity: {
            email: email,
            phone_number: ""
          }
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    if (needScroll && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
    //if (scrollRef.current) {
    //  scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    //}
    const reader = response.body
      ?.pipeThrough(new TextDecoderStream())
      .getReader()
    if (reader) {
      while (true) {
        const { value, done } = await reader.read()
        let needScroll = false
        let scrollTop = 0
        if (done) {
          setChatHistory((history) => {
            const newHistory = [...history]
            newHistory[newHistory.length - 1].finished = true
            try {
              fetch(
                'https://agent-management.onrender.com/conversation_by_api_key',
                {
                  method: 'POST',
                  body: JSON.stringify({
                    api_key: apiKey,
                    chat_history: newHistory.map((sentence) => {
                      return {
                        role: sentence.role,
                        content: sentence.content,
                      }
                    }),
                    conversation_id: conversationId,
                    end_user_identity: {
                      email: email,
                      phone_number: ""
                    }
                  }),
                  headers: {
                    'Content-Type': 'application/json',
                  },
                },
              )
            } catch {

            }
            return newHistory
          })
          //if (scrollRef.current) {
          //  scrollRef.current.scrollTop = scrollRef.current.scrollHeight
          //}
          break
        }
        if (scrollRef.current) {
          if (
            Math.abs(
              scrollRef.current.scrollHeight -
              scrollRef.current.clientHeight -
              scrollRef.current.scrollTop,
            ) <= 1
          ) {
            needScroll = true
          }
          scrollTop = scrollRef.current.scrollTop
        }
        setChatHistory((history) => {
          setMessageRead(false)
          const newHistory = [...history]
          if (process.env.NODE_ENV === 'development') {
            if (!newHistory[newHistory.length - 1].content.endsWith(value)) {
              newHistory[newHistory.length - 1].content += value
            }
          } else {
            newHistory[newHistory.length - 1].content += value
          }
          newHistory[newHistory.length - 1].backgroundUpdated =
            !isOpenRef.current
          return newHistory
        })
        await timeout(100)
        if (
          needScroll &&
          scrollRef.current &&
          scrollTop === scrollRef.current.scrollTop
        ) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        }
      }
    }
    //if (scrollRef.current) {
    //  scrollRef.current.style.overflow = 'auto'
    //}
  }

  const chatBotInner = !emailConfirmed ? (
    <div
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <div
        style={{ borderTopLeftRadius: 24, borderTopRightRadius: 24, minHeight: 0 }}
      >
        <div style={{ overflow: 'hidden', maxHeight: 420, borderTopLeftRadius: 24, borderTopRightRadius: 24 }}>
          <img
            width={width}
            src="https://servicegenie.ai/EmailInput.jpg"
          />
        </div>
        <div
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: 24,
            top: 24,
          }}
          onClick={() => {
            if (windowRef.current) {
              windowRef.current.classList.remove('maximize-animation')
              windowRef.current.classList.add('minimize-animation')
            }
            setTimeout(() => setIsOpen(false), DURATION)
            isOpenRef.current = false
          }}
        >
          <img src="https://servicegenie.ai/CloseFullscreen.svg" />
        </div>
      </div>
      <div
        style={{
          borderBottomLeftRadius: isMobile ? 0 : 24,
          borderBottomRightRadius: isMobile ? 0 : 24,
          width: '100%',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          padding: 24,
          gap: 20,
          flexGrow: 1,
          justifyContent: 'space-evenly',
          minHeight: 270
        }}
      >
        <div
          style={{ fontSize: 24, lineHeight: "32px", fontWeight: 700 }}
        >
          Hi there 👋
        </div>
        <div style={{ fontWeight: 400 }}>
          In order to enhance your customer experience, we kindly ask for your
          email address to begin:
        </div>
        <div style={{ position: 'relative' }}>
          <ChatBotEmailInput
            placeholder="Type your email to start chat"
            value={email}
            onChange={(e: any) => {
              setShowEmailError(false)
              setEmail((e.target as HTMLInputElement).value)
            }}
          />
          <div
            style={{
              position: 'absolute',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              top: 0,
              right: 16,
            }}
          >
            <div

              style={{
                height: 40,
                width: 40,
                borderRadius: 9999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: email === '' ? '#e5e7eb' : '#FAAB00',
                cursor: email === '' ? 'not-allowed' : 'pointer',
              }}
              onClick={() => {
                if (
                  email
                    .toLowerCase()
                    .match(
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    )
                ) {
                  localStorage.setItem('servicegenie-email', email)
                  setEmailConfirmed(true)
                } else {
                  setShowEmailError(true)
                }
              }}
            >
              <img
                src="https://servicegenie.ai/SendMessage.svg"
                style={{
                  fill: 'white',
                  height: 28,
                  width: 28,
                  paddingLeft: 4,
                }}
              />
            </div>
          </div>
        </div>
        {showEmailError && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              color: '#b91c1cs',
            }}
          >
            <img
              src="https://servicegenie.ai/Error.svg"
              style={{ width: 24, height: 24 }}
            />
            Invalid email address, please try again
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
    >
      <div
        style={{
          borderTopLeftRadius: 24,
          borderTopRightRadius: 24,
          width: '100%',
          height: 68,
          backgroundColor: '#FAAB00',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 24,
        }}
      >
        <div
          style={{ display: 'flex', gap: 16, alignItems: 'center' }}
        >
          <img src="https://servicegenie.ai/Header.svg" />
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: 4 }}
          >
            <div
              style={{ fontWeight: 600, color: 'white' }}
            >
              {data?.name}
            </div>
            <div
              style={{
                color: 'white',
                fontSize: 12,
                lineHeight: "16px",
                fontWeight: 400,
              }}
            >
              Powered by ServiceGenie.AI
            </div>
          </div>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (windowRef.current) {
              windowRef.current.classList.remove('maximize-animation')
              windowRef.current.classList.add('minimize-animation')
            }
            setTimeout(() => setIsOpen(false), DURATION)
            isOpenRef.current = false
          }}
        >
          <img src="https://servicegenie.ai/CloseFullscreen.svg" />
        </div>
      </div>
      <div
        style={{
          maxHeight: isMobile ? undefined : height - 68,
          borderBottomLeftRadius: isMobile ? 0 : 24,
          borderBottomRightRadius: isMobile ? 0 : 24,
          width: '100%',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          minHeight: 0,
        }}
      >
        <div
          ref={scrollRef}
          style={{ flexGrow: 1, overflow: 'auto' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              width: '100%',
              padding: 24,
            }}
          >
            {readHistory.map((message: any) => {
              if (message.role !== 'human' && message.role != 'ai') {
                return null
              }
              if (message.role === 'ai') {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      gap: 12,
                      minWidth: 0,
                      paddingRight: isMobile ? 24 : 0
                    }}
                  >
                    <div
                      style={{
                        height: 40,
                        width: 40,
                        borderRadius: 9999,
                        backgroundColor: '#FAAB00',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: 4,
                        flexShrink: 0,
                      }}
                    >
                      <img
                        src="https://servicegenie.ai/Diamond.svg"
                        width={28}
                        height={28}
                      />
                    </div>
                    <div
                      style={{
                        borderRadius: '0px 20px 20px 20px',
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                        fontWeight: 400,
                        maxWidth: 750,
                        backgroundColor: '#f9fafb',
                        minWidth: 0,
                      }}
                      className="conversation"
                    >
                      {message.content ? (
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            a: LinkRenderer,
                          }}
                        >
                          {message.content}
                        </ReactMarkdown>
                      ) : (
                        <BeatLoader size={6} color="#D9D9D9" />
                      )}
                    </div>
                  </div>
                )
              } else {
                return (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      gap: 12,
                      paddingLeft: isMobile ? 64 : 0,
                      flexDirection: 'row-reverse',
                      minWidth: 0,
                    }}
                  >
                    <div
                      style={{
                        borderRadius: '20px 0px 20px 20px',
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingTop: 8,
                        paddingBottom: 8,
                        color: 'white',
                        fontWeight: 400,
                        maxWidth: 750,
                        backgroundColor: '#374151',
                        minWidth: 0,
                      }}
                      className="conversation"
                    >
                      {message.content ? (
                        <ReactMarkdown
                          components={{
                            a: LinkRenderer,
                          }}
                        >
                          {message.content}
                        </ReactMarkdown>
                      ) : (
                        <BeatLoader size={6} />
                      )}
                    </div>
                  </div>
                )
              }
            })}
            {unreadHistory.length > 0 ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    gap: 8,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{ height: 1, width: 20, backgroundColor: '#6b7280' }}
                  ></div>
                  <div
                    style={{ fontSize: 12, lineHeight: "16px", color: '#6b7280' }}
                  >
                    New message
                  </div>
                  <div
                    style={{ height: 1, width: 20, backgroundColor: '#6b7280' }}
                  ></div>
                </div>
                {unreadHistory.map((message: any) => {
                  if (message.role !== 'human' && message.role != 'ai') {
                    return null
                  }
                  if (message.role === 'ai') {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'start',
                          width: '100%',
                          gap: 12,
                          minWidth: 0,
                        }}
                      >
                        <div
                          style={{
                            height: 40,
                            width: 40,
                            borderRadius: 9999,
                            backgroundColor: '#FAAB00',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: 4,
                            flexShrink: 0,
                          }}
                        >
                          <img
                            src="https://servicegenie.ai/Diamond.svg"
                            width={28}
                            height={28}
                          />
                        </div>
                        <div
                          style={{
                            borderRadius: '0px 20px 20px 20px',
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingTop: 8,
                            paddingBottom: 8,
                            fontWeight: 400,
                            maxWidth: 750,
                            backgroundColor: '#f9fafb',
                            minWidth: 0,
                          }}
                          className="conversation"
                        >
                          {message.content ? (
                            <ReactMarkdown
                              remarkPlugins={[remarkGfm]}
                              components={{
                                a: LinkRenderer,
                              }}
                            >
                              {message.content}
                            </ReactMarkdown>
                          ) : (
                            <BeatLoader size={6} color="#D9D9D9" />
                          )}
                        </div>
                      </div>
                    )
                  } else {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          width: '100%',
                          gap: 12,
                          flexDirection: 'row-reverse',
                          minWidth: 0,
                        }}
                      >
                        <div
                          style={{
                            borderRadius: '20px 0px 20px 20px',
                            paddingLeft: 12,
                            paddingRight: 12,
                            paddingTop: 8,
                            paddingBottom: 8,
                            color: 'white',
                            fontWeight: 400,
                            maxWidth: 750,
                            backgroundColor: '#374151',
                            minWidth: 0,
                          }}
                          className="conversation"
                        >
                          {message.content ? (
                            <ReactMarkdown
                              components={{
                                a: LinkRenderer,
                              }}
                            >
                              {message.content}
                            </ReactMarkdown>
                          ) : (
                            <BeatLoader size={6} color="#D9D9D9" />
                          )}
                        </div>
                      </div>
                    )
                  }
                })}
              </>
            ) : null}
          </div>
        </div>
        {chatHistory.length === 1 ? (
          <div
            style={{
              display: viewport.height > 550 ? 'flex' : 'none',
              flexDirection: 'column',
              gap: 16,
              paddingLeft: 24,
              paddingRight: 24,
              justifyContent: 'flex-end',
            }}
          >
            <div>Example questions:</div>
            {exampleQuestions.map((question) => (
              <div
                style={{
                  boxShadow: '0px 2px 4px 0px #00000040',
                  backgroundColor: '#FFDF99',
                  borderRadius: 20,
                  paddingLeft: 12,
                  paddingRight: 12,
                  paddingTop: 8,
                  paddingBottom: 8,
                  display: 'flex',
                  width: 'fit-content',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  handleSubmit(question)
                }}
              >
                {question}
              </div>
            ))}
          </div>
        ) : null}
        <div style={{ position: 'relative', padding: 24 }}>
          <ChatBotInput
            placeholder="Type your message..."
            onSubmit={() => handleSubmit()}
            value={instruction}
            onChange={(e: any) => {
              setInstruction((e.target as HTMLTextAreaElement).value)
            }}
          />
          <div
            style={{
              position: 'absolute',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              top: 0,
              right: 36,
            }}
          >
            <div

              style={{
                height: 40,
                width: 40,
                borderRadius: 9999,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: email === '' ? '#e5e7eb' : '#FAAB00',
                cursor: email === '' ? 'not-allowed' : 'pointer',
              }}
              onClick={() => {
                handleSubmit()
              }}
            >
              <img
                src="https://servicegenie.ai/SendMessage.svg"
                style={{
                  fill: 'white',
                  height: 28,
                  width: 28,
                  paddingLeft: 4,
                }}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  )

  if (!data || typeof data === 'string') {
    return null
  }
  return (
    <div
      className="service-genie-chatbot"
      style={{
        bottom,
        right,
        position: 'fixed',
        zIndex: 500,
        color: 'black',
        fontSize: 16,
        lineHeight: "24px",
      }}
    >
      <div
        style={{
          boxShadow: '0px 2px 4px 0px #00000040',
          height: 60,
          width: 60,
          borderRadius: 9999,
          backgroundColor: '#FAAB00',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          paddingTop: 4,
        }}
        onClick={async () => {
          setShowWelcome(false)
          if (isOpen) {
            if (windowRef.current) {
              windowRef.current.classList.remove('maximize-animation')
              windowRef.current.classList.add('minimize-animation')
            }
            setTimeout(() => setIsOpen(false), DURATION)
          } else {
            setMessageRead(true)
            if (windowRef.current) {
              windowRef.current.classList.remove('minimize-animation')
              windowRef.current.classList.add('maximize-animation')
            }
            setTimeout(() => setIsOpen(true), 50)
          }
          isOpenRef.current = !isOpenRef.current
        }}
      >
        <img src="https://servicegenie.ai/Diamond.svg" />
        {!isOpen && chatHistory[chatHistory.length - 1]?.backgroundUpdated && !messageRead && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              width: 16,
              height: 16,
              borderRadius: 9999,
              backgroundColor: '#b91c1c',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{ fontSize: 12, color: 'white' }}
            >
              1
            </div>
          </div>
        )}
      </div>
      {showWelcome && shouldShowWelcomeMessage && (
        <>
          <div
            style={{
              boxShadow: '0px 2px 4px 0px #00000040',
              position: 'absolute',
              borderRadius: 24,
              bottom: 76,
              right: 0,
              width: isMobile ? screenWidth - right - 56 : 360,
              padding: 24,
              backgroundColor: 'white',
            }}
          >
            <div style={{ fontWeight: 400 }}>
              Welcome! Our customer service chatbot is here and ready to assist
              you with any questions you may have about orders, tracking,
              returns, and more!
            </div>
            <div style={{ position: 'absolute', top: -4, left: -32 }}>
              <img
                src="https://servicegenie.ai/Cancel.svg"
                onClick={() => {
                  setShowWelcome(false)
                }}
              />
            </div>
          </div>
        </>
      )}
      <div
        ref={windowRef}
        className={classNames('maximize-animation')}
        style={{ position: 'absolute', display: isOpen ? undefined : 'none' }}
      >
        {isMobile ? (
          <div
            style={{
              height: viewport.height,
              width: viewport.width,
              right: -(60 + right),
              bottom: -bottom,
              position: 'fixed'
            }}
          >
            {chatBotInner}
          </div>
        ) : (
          <Rnd
            default={{
              x: 0,
              y: 0,
              width,
              height,
            }}
            size={{ height, width }}
            style={{
              boxShadow: '0px 2px 4px 0px #00000040',
              left: -420,
              top: -660,
              borderRadius: 24,
              display: 'flex',
              flexDirection: 'column'
            }}
            onResize={(e, direction, ref, delta, position) => {
              setWidth(parseInt(ref.style.width))
              setHeight(parseInt(ref.style.height))
            }}
            disableDragging
            enableResizing={{
              bottom: false,
              bottomLeft: false,
              bottomRight: false,
              left: emailConfirmed,
              right: false,
              top: emailConfirmed,
              topLeft: emailConfirmed,
              topRight: false,
            }}
            minHeight={chatHistory.length === 1 ? 550 : 300}
            minWidth={350}
          >
            {chatBotInner}
          </Rnd>
        )}
      </div>
    </div>
  )
}

export default ChatBot
