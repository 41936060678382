import { useMutation } from '@tanstack/react-query'
import useAuthStore from '../store/auth'
import { axiosWithAuth } from '../utils/axios'

interface DeleteAgentRequest {
  agentId?: string
}

export const useDeleteAgent = () => {
  const axios = axiosWithAuth()
  const deleteAgent = async ({ agentId }: DeleteAgentRequest) => {
    if (!agentId) {
      return
    }
    try {
      const res = await axios.delete(`agent/${agentId}`)
      return res
    } catch (e) {
      return null
    } finally {
    }
  }

  const { mutateAsync } = useMutation(deleteAgent)
  return { mutateAsync }
}
