import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonType } from '../components/Button';
import Input from '../components/Input';
import Modal from 'react-modal';
import { ReactComponent as Success } from '../static/Success.svg';
import useAuthStore from '../store/auth';
import { useRegister } from '../query/useRegister';




function Register() {
  const navigate = useNavigate()
  const { successModalOpen, setSuccessModalOpen, loading } = useAuthStore()
  const { mutate } = useRegister()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const enableRegister = Boolean(email) && Boolean(password) && Boolean(passwordConfirm) && password === passwordConfirm
  return (
    <div className='w-full flex justify-center'>
      <Modal
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        isOpen={successModalOpen}
        onAfterOpen={() => {
          setTimeout(() => {
            setSuccessModalOpen(false)
            navigate('/login')
          }, 2000)
        }}
        onRequestClose={() => { setSuccessModalOpen(false) }}
        style={{
          content: {
            width: 400,
            height: 145,
            inset: "unset",
            padding: 0,
            border: "none",
            borderRadius: "16px",
            boxShadow:
              "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
            overflow: "hidden",
          },
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.55)",
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className='flex flex-col items-center justify-center h-full gap-4 p-6'>
          <Success />
          <div className='text-2xl font-semibold'>Account created</div>
        </div>
      </Modal>
      <div className='w-[540px] flex flex-col pt-[100px] items-center'>
        <div className='text-[40px] font-bold'>Sign up</div>
        <div className='flex flex-col items-center mt-10 gap-6 w-full mb-8'>
          <div className='flex flex-col items-stretch gap-2 w-full'>
            <div className='text-base font-bold pl-6'>{"Email (Required)"}</div>
            <Input value={email} isError={false} onChange={(e) => {
              setEmail((e.target as HTMLInputElement).value)
            }} />
          </div>
          <div className='flex flex-col items-stretch gap-2 w-full'>
            <div className='text-base font-bold pl-6'>{"Password (Required)"}</div>
            <Input value={password} isError={false} type="password" onChange={(e) => {
              setPassword((e.target as HTMLInputElement).value)
            }} />
          </div>
          <div className='flex flex-col items-stretch gap-2 w-full'>
            <div className='text-base font-bold pl-6'>{"Confirm Password (Required)"}</div>
            <Input value={passwordConfirm} isError={false} type="password" onChange={(e) => {
              setPasswordConfirm((e.target as HTMLInputElement).value)
            }} />
          </div>
        </div>
        <div className='flex gap-4 pb-[100px]'>
          <Button type={ButtonType.SECONDARY} content="Sign in" loading={loading} onClick={() => { navigate("/login") }} />
          <Button type={ButtonType.PRIMARY} content="Sign up" loading={loading} disabled={!enableRegister} onClick={async () => {
            mutate({
              email,
              password
            })
          }} />
        </div>
      </div>
    </div>
  );
}

export default Register;
