import create from 'zustand'

export interface Knowledge {
  name: string
  s3_key: string
  gcs_blob_name: string
  status: FileStatus
  download_url: string
}

export enum FileStatus {
  UPLOADING = 'uploading',
  PROCESSING = 'processing',
  SUCCEED = 'succeed',
  FAILED = 'failed',
}

export function getTextForStatus(status: FileStatus) {
  switch (status) {
    case FileStatus.UPLOADING:
      return 'uploading'
    case FileStatus.PROCESSING:
      return 'processing'
    case FileStatus.SUCCEED:
      return 'ready'
    case FileStatus.FAILED:
      return 'failed'
    default:
      return 'ready'
  }
}

export interface Agent {
  id: string
  instruction: string
  knowledges: Knowledge[]
  name: string
  open_message: string
  phone_numbers: string[]
}

type AgentInfoStore = {
  loading: boolean
  setLoading: (value: boolean) => void
  agentId: string
  name: string
  setName: (value: string) => void
  instruction: string
  setInstruction: (value: string) => void
  openMessage: string
  setOpenMessage: (value: string) => void
  knowledges: Knowledge[]
  addToKnowledge: (fileName: string, key: string) => void
  removeKnowledge: (index: number) => void
  initForCreate: () => void
  initForUpdate: (info: Agent) => void
  successModalOpen: boolean
  setSuccessModalOpen: (value: boolean) => void
  errorModalOpen: boolean
  setErrorModalOpen: (value: boolean) => void
}

const useAgentInfoStore = create<AgentInfoStore>((set) => ({
  loading: false,
  setLoading: (value: boolean) => set({ loading: value }),
  agentId: '-1',
  name: '',
  setName: (value: string) => set({ name: value }),
  instruction: '',
  setInstruction: (value: string) => set({ instruction: value }),
  openMessage: '',
  setOpenMessage: (value: string) => set({ openMessage: value }),
  knowledges: [],
  addToKnowledge: (fileName: string, key: string) =>
    set((state) => {
      state.knowledges = [
        ...state.knowledges,
        {
          name: fileName,
          s3_key: '',
          gcs_blob_name: key,
          status: FileStatus.UPLOADING,
          download_url: '',
        },
      ]
      return { ...state }
    }),
  removeKnowledge: (index: number) =>
    set((state) => {
      if (index >= state.knowledges.length) {
        return { ...state }
      } else {
        state.knowledges = [
          ...state.knowledges.slice(0, index),
          ...state.knowledges.slice(index + 1),
        ]
        return { ...state }
      }
    }),
  initForCreate: () =>
    set({
      agentId: '-1',
      name: '',
      instruction: '',
      openMessage: '',
      knowledges: [],
    }),
  initForUpdate: (info: Agent) =>
    set({
      agentId: info.id,
      name: info.name,
      instruction: info.instruction,
      openMessage: info.open_message,
      knowledges: info.knowledges,
    }),
  successModalOpen: false,
  setSuccessModalOpen: (value: boolean) => set({ successModalOpen: value }),
  errorModalOpen: false,
  setErrorModalOpen: (value: boolean) => set({ errorModalOpen: value }),
}))

export default useAgentInfoStore
