import { useQuery } from '@tanstack/react-query'
import { axiosWithoutAuth } from '../utils/axios'

export const useAgentApiKey = (agentId?: string) => {
  const axios = axiosWithoutAuth()
  return useQuery({
    queryKey: ['agentApiKey', agentId?.toString()],
    queryFn: async () => {
      if (!agentId) {
        return null
      }
      try {
        const {
          data: { result },
        } = await axios.get(`api_key?agent_id=${agentId}`)
        return result
      } catch (e: any) {
        return null
      }
    },
    refetchOnWindowFocus: false,
  })
}
