import { useQuery } from '@tanstack/react-query'
import { start } from 'repl'
import {
  FeedbackType,
  SummarySort,
  SummarySource,
  useStoreFilterStore,
  useSummaryFilterStore,
} from '../store/filter'
import { axiosWithAuth } from '../utils/axios'
import { PAGE_LIMIT } from '../utils/const'
export const useStoreFeedback = (offset: number, storeName: string | null) => {
  const axios = axiosWithAuth()
  const { timeRange, sortMethod, source, startAt, endAt, feedbackType } =
    useStoreFilterStore()
  const getStoreName = (storeName: SummarySource) => {
    if (storeName === SummarySource.GOOGLE_MAP) {
      return 'google_map'
    } else if (storeName === SummarySource.YELP) {
      return 'yelp'
    }
    return 'google_map'
  }

  let feedTypeFilter: any = null
  if (feedbackType.length === 1) {
    if (feedbackType[0] === FeedbackType.POSITIVE) {
      feedTypeFilter = 'Positive'
    } else {
      feedTypeFilter = 'Negative'
    }
  }

  return useQuery({
    queryKey: [
      'store_feedback',
      timeRange,
      sortMethod,
      source,
      startAt,
      endAt,
      feedbackType,
    ],
    queryFn: async () => {
      try {
        const {
          data: { result },
        } = await axios.post('feedback_search', {
          social_app_name: getStoreName(source),
          feedback_type: feedTypeFilter,
          store_name: storeName,
          start_at: startAt.toISOString(),
          end_at: endAt.toISOString(),
          star_list: null,
          offset,
          compliant_severity_list: null,
          status_list: null,
          limit: PAGE_LIMIT,
        })
        return result
      } catch (e: any) {
        return []
      }
    },
    refetchOnWindowFocus: false,
  })
}
