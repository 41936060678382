import create from 'zustand'

export enum TimeRange {
  PAST_WEEK,
  PAST_MONTH,
  CUSTOM_DATE,
}

export enum SummarySource {
  GOOGLE_MAP,
  YELP,
}

export enum SummarySort {
  MOST_REVIEW,
  HIGHEST_RATING,
}

export enum StoreSort {
  LATEST_REVIEW,
  //NEGATIVE_REVIEW,
}

export enum FeedbackType {
  POSITIVE,
  NEGATIVE,
}

type StoreFilterStore = {
  timeRange: TimeRange
  setTimeRange: (value: TimeRange) => void
  source: SummarySource
  setSource: (value: SummarySource) => void
  sortMethod: StoreSort
  setSortMethod: (value: StoreSort) => void
  startAt: Date
  setStartAt: (value: Date) => void
  endAt: Date
  setEndAt: (value: Date) => void
  feedbackType: FeedbackType[]
  setFeedbackType: (value: FeedbackType[]) => void
}

export const useStoreFilterStore = create<StoreFilterStore>((set) => {
  const today = new Date()
  const dayOfWeek = today.getDay()
  const daysToLastMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1) + 7
  const lastMonday = new Date(today)
  lastMonday.setDate(today.getDate() - daysToLastMonday)
  const lastSunday = new Date(lastMonday)
  lastSunday.setDate(lastMonday.getDate() + 6)
  return {
    timeRange: TimeRange.PAST_WEEK,
    setTimeRange: (value: TimeRange) => set({ timeRange: value }),
    source: SummarySource.GOOGLE_MAP,
    setSource: (value: SummarySource) => set({ source: value }),
    sortMethod: StoreSort.LATEST_REVIEW,
    setSortMethod: (value: StoreSort) => set({ sortMethod: value }),
    startAt: lastMonday,
    setStartAt: (value: Date) => set({ startAt: value }),
    endAt: lastSunday,
    setEndAt: (value: Date) => set({ endAt: value }),
    feedbackType: [FeedbackType.POSITIVE, FeedbackType.NEGATIVE],
    setFeedbackType: (value: FeedbackType[]) => set({ feedbackType: value }),
  }
})

type SummaryFilterStore = {
  timeRange: TimeRange
  setTimeRange: (value: TimeRange) => void
  source: SummarySource
  setSource: (value: SummarySource) => void
  sortMethod: SummarySort
  setSortMethod: (value: SummarySort) => void
  startAt: Date
  setStartAt: (value: Date) => void
  endAt: Date
  setEndAt: (value: Date) => void
}

export const useSummaryFilterStore = create<SummaryFilterStore>((set) => {
  const today = new Date()
  const dayOfWeek = today.getDay()
  const daysToLastMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1) + 7
  const lastMonday = new Date(today)
  lastMonday.setDate(today.getDate() - daysToLastMonday)
  const lastSunday = new Date(lastMonday)
  lastSunday.setDate(lastMonday.getDate() + 6)
  return {
    timeRange: TimeRange.PAST_WEEK,
    setTimeRange: (value: TimeRange) => set({ timeRange: value }),
    source: SummarySource.GOOGLE_MAP,
    setSource: (value: SummarySource) => set({ source: value }),
    sortMethod: SummarySort.MOST_REVIEW,
    setSortMethod: (value: SummarySort) => set({ sortMethod: value }),
    startAt: lastMonday,
    setStartAt: (value: Date) => set({ startAt: value }),
    endAt: lastSunday,
    setEndAt: (value: Date) => set({ endAt: value }),
  }
})
