import classnames from 'classnames';
import Button, { ButtonType } from './Button';
import { ReactComponent as OpenTab } from '../static/OpenTab.svg';


type Agent = {
  name: string,
  id: number,
  agent_type_id: number
}
type Props = { agents: Agent[] };

const AgentList = ({
  agents
}: Props) => {
  return (
    <div className='flex flex-col w-full'>
      {
        agents.map((agent, index) => <div key={agent.id} className={classnames("h-[80px] px-6 py-4 flex items-start justify-between", {
          "bg-gray-50": index % 2 === 0,
          "bg-white": index % 2 === 1,
        })}>
          <div className='text-base font-bold'>{agent.name}</div>
          <div className='flex items-center gap-6'>
            <Button type={ButtonType.SECONDARY} content="Test agent" Icon={OpenTab} onClick={async () => {
              window.open(`/test/${agent.id}`, '_blank')
            }} />
            <Button type={ButtonType.SECONDARY} content="View details" Icon={OpenTab} onClick={async () => {
              window.open(`/agent/${agent.id}`, '_blank')
            }} />
          </div>
        </div>)
      }
    </div>
  );
};

export default AgentList;
