import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'
import Button, { ButtonType } from '../components/Button'
import useAgentInfoStore from '../store/agentInfo'
import { ReactComponent as File } from '../static/File.svg'
import Input from '../components/Input'
import TextArea from '../components/TextArea'
import Upload from '../components/Upload'
import { useUploadFile } from '../query/useUploadFile'
import { ReactComponent as Delete } from '../static/Delete.svg'
import { useCreateAgent } from '../query/useCreateAgent'
import Modal from 'react-modal'
import { ReactComponent as Success } from '../static/Success.svg'
import { ReactComponent as Error } from '../static/Error.svg'
import Navbar from '../components/Navbar'

function CreateAgent() {
  const {
    name,
    setName,
    instruction,
    setInstruction,
    openMessage,
    setOpenMessage,
    knowledges,
    addToKnowledge,
    removeKnowledge,
    initForCreate,
    successModalOpen,
    setSuccessModalOpen,
    errorModalOpen,
    setErrorModalOpen,
  } = useAgentInfoStore()
  const navigate = useNavigate()
  const { mutateAsync: uploadFile } = useUploadFile()
  const { mutate: createAgent } = useCreateAgent()
  const enableCreate =
    Boolean(name) && Boolean(instruction) && Boolean(openMessage)
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    initForCreate()
  }, [])
  return (
    <div className="flex justify-center pt-[156px] pb-[200px]">
      <Navbar />
      <Modal
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        isOpen={successModalOpen}
        onAfterOpen={() => {
          setTimeout(() => {
            setSuccessModalOpen(false)
            navigate('/agents')
          }, 2000)
        }}
        onRequestClose={() => {
          setSuccessModalOpen(false)
        }}
        style={{
          content: {
            width: 400,
            height: 200,
            inset: 'unset',
            padding: 0,
            border: 'none',
            borderRadius: '16px',
            boxShadow:
              '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
            overflow: 'hidden',
          },
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.55)',
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col items-center justify-center h-full gap-4 p-6">
          <Success />
          <div className="text-2xl font-semibold">Agent created</div>
        </div>
      </Modal>
      <Modal
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        isOpen={errorModalOpen}
        onRequestClose={() => {
          setErrorModalOpen(false)
        }}
        style={{
          content: {
            width: 400,
            height: 256,
            inset: 'unset',
            padding: 0,
            border: 'none',
            borderRadius: '16px',
            boxShadow:
              '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
            overflow: 'hidden',
          },
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.55)',
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col items-center justify-center h-full gap-4 p-6">
          <Error />
          <div className="text-2xl font-semibold">File cannot upload</div>
          <div className="text-base text-center">{`The file exceeds file size limit, please try a smaller file.`}</div>
          <Button
            type={ButtonType.PRIMARY}
            content="OK"
            onClick={async () => {
              setErrorModalOpen(false)
            }}
          />
        </div>
      </Modal>
      <div className="flex flex-col items-center w-[1024px] gap-6">
        <div className="flex items-center justify-start mb-[40px] w-full px-6 py-4">
          <div className="text-[40px] font-bold">Create agent</div>
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-col gap-2 px-6 w-full">
            <div className="text-base font-bold">Voice agent name</div>
            <div className="text-base">Required, 250 characters max</div>
          </div>
          <Input
            value={name}
            isError={false}
            onChange={(e) => {
              setName((e.target as HTMLInputElement).value)
            }}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-col gap-2 px-6 w-full">
            <div className="text-base font-bold">Instructions</div>
            <div className="text-base">Required, 2000 characters max</div>
          </div>
          <TextArea
            value={instruction}
            isError={false}
            onChange={(e) => {
              setInstruction((e.target as HTMLInputElement).value)
            }}
          />
        </div>
        <div className="flex flex-col gap-2 w-full">
          <div className="flex flex-col gap-2 px-6 w-full">
            <div className="text-base font-bold">Open message</div>
            <div className="text-base">Required, 250 characters max</div>
          </div>
          <Input
            value={openMessage}
            isError={false}
            onChange={(e) => {
              setOpenMessage((e.target as HTMLInputElement).value)
            }}
          />
        </div>
        <div className="flex flex-col w-full gap-4">
          <div className="flex flex-col gap-1 px-6 w-full">
            <div className="text-base font-bold">Knowledge</div>
            <div className="text-base">{'20 files max, each file 20MB max (PDF 150MB max)'}</div>
          </div>
          <div className="flex flex-wrap gap-3">
            {knowledges.map((knowledge, index) => (
              <div className="w-[506px] bg-blue-50 px-6 py-3 flex items-center rounded-lg">
                <div className="flex gap-2 items-center justify-start grow">
                  <File />
                  <div>{knowledge.name}</div>
                </div>
                <Delete
                  className="fill-blue-500 cursor-pointer"
                  onClick={() => {
                    removeKnowledge(index)
                  }}
                />
              </div>
            ))}
          </div>
          {knowledges.length < 20 && (
            <Upload
              content="Upload knowledge file"
              onBeforeUpload={async (file) => {
                if (file) {
                  if (file.name.toLowerCase().endsWith('.pdf') || file.type === 'application/pdf') {
                    if (file.size > 150 * 1024 * 1024) {
                      setErrorModalOpen(true)
                    } else {
                      setUploading(true)
                      await uploadFile(file)
                      setUploading(false)
                    }
                  } else {
                    if (file.size > 20 * 1024 * 1024) {
                      setErrorModalOpen(true)
                    } else {
                      setUploading(true)
                      await uploadFile(file)
                      setUploading(false)
                    }
                  }
                }
              }}
              loading={uploading}
            />
          )}
        </div>

        <div className="fixed flex items-center justify-center w-full py-6 bottom-0 gap-4 border-t border-t-gray-200 bg-white">
          <Button
            type={ButtonType.PRIMARY}
            content="Create new agent"
            disabled={!enableCreate || uploading}
            onClick={() => {
              createAgent()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default CreateAgent
