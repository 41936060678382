// @ts-nocheck

import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

type Props = HTMLProps<HTMLTextAreaElement>;

const ChatBotInput = ({
  placeholder,
  value,
  onFocus,
  onBlur,
  className,
  onChange,
  onSubmit,
  ...props
}: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = 'auto'
      ref.current.style.height = `${ref.current.scrollHeight}px`
    }
  }, [value])

  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 100,
        paddingLeft: 24,
        paddingRight: 54,
        paddingTop: 19,
        paddingBottom: 19,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#e5e7eb',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <textarea
        ref={ref}
        rows={1}
        style={{
          borderStyle: 'none',
          outline: '2px solid transparent',
          outlineOffset: 2,
          width: "100%",
          backgroundColor: "transparent",
          color: '#1f2937',
          fontSize: 16,
          lineHeight: "24px",
          resize: 'none',
          overflowY: 'scroll',
          maxHeight: 48,
          minHeight: 0
        }}
        placeholder={placeholder}
        onChange={e => {
          if (ref.current) {
            ref.current.style.height = 'auto'
            ref.current.style.height = `${ref.current.scrollHeight}px`
          }
          onChange?.(e);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            onSubmit?.(e)
          }
        }}
        value={value}
        enterKeyHint='send'
        {...props}
        autoComplete='off'
      />
    </div>
  );
};

export default ChatBotInput;
