import classnames from 'classnames'
import Button, { ButtonType } from './Button'
import { ReactComponent as OpenTab } from '../static/OpenTab.svg'

type Conversation = {
  summary: string
  id: number
  end_user_identity: { email: string }
  create_time: string
}
type Props = { conversations: Conversation[] }

const ConversationList = ({ conversations }: Props) => {
  return (
    <div className="flex flex-col w-full">
      {conversations.map((conversation, index) => (
        <div
          key={conversation.id}
          className={classnames(
            'h-[80px] px-6 py-4 flex items-start justify-between',
            {
              'bg-gray-50': index % 2 === 0,
              'bg-white': index % 2 === 1,
            },
          )}
        >
          <div className="flex flex-col gap-2 items-start">
            <div className="text-base font-bold">
              {conversation?.end_user_identity?.email}
            </div>
            <div>{new Date(conversation.create_time).toLocaleString()}</div>
          </div>
          <Button
            type={ButtonType.SECONDARY}
            content="View details"
            Icon={OpenTab}
            onClick={async () => {
              window.open(`/conversation/${conversation.id}`, '_blank')
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default ConversationList
