import { useEffect, useState } from 'react';

export default function useVisualViewport() {
  const getKeyboardHeight = () =>
    window.innerHeight - (window?.visualViewport?.height ?? window.innerHeight);
  const [viewport, setViewport] = useState({
    keyboardHeight: getKeyboardHeight(),
    height: window?.visualViewport?.height ?? window.innerHeight,
    width: window?.visualViewport?.width ?? window.innerWidth,
    offset: window.pageYOffset,
  });

  useEffect(() => {
    const handler = () => {
      if (!window.visualViewport) return;
      setViewport((viewport) => ({
        ...viewport,
        keyboardHeight: getKeyboardHeight(),
        height: window.visualViewport!.height,
        width: window?.visualViewport?.width ?? window.innerWidth,
        offset: window.pageYOffset,
      }));
      //window.scrollTo(0, 0);
    };

    window?.visualViewport?.addEventListener('resize', handler);
    window?.visualViewport?.addEventListener('scroll', handler);

    return () => {
      window?.visualViewport?.removeEventListener('resize', handler);
      window?.visualViewport?.removeEventListener('scroll', handler);
    };
  }, []);

  return viewport;
}
