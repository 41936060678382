import { useQuery } from '@tanstack/react-query'
import { axiosWithAuth } from '../utils/axios'

export const useAgentDetail = (agentId?: string) => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['agentDetail', agentId?.toString()],
    queryFn: async () => {
      if (!agentId) {
        return null
      }
      try {
        const {
          data: { result },
        } = await axios.get(`agent/${agentId}`)
        return result
      } catch (e: any) {
        return null
      }
    },
    refetchOnWindowFocus: false,
  })
}
