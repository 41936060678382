import React, { useState } from 'react';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonType } from '../components/Button';
import Input from '../components/Input';
import qs from 'qs';
import Modal from 'react-modal';
import { ReactComponent as Error } from '../static/Error.svg';
import { axiosWithAuth } from '../utils/axios'
import useAuthStore from '../store/auth';
import { useLogin } from '../query/useLogin';


function ReviewManagementLogin() {
  const navigate = useNavigate()
  const { errorModalOpen, setErrorModalOpen, loading } = useAuthStore()
  const { mutateAsync } = useLogin()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const enableLogin = Boolean(email) && Boolean(password)
  return (
    <div className='w-full flex justify-center'>
      <Modal
        ariaHideApp={false}
        isOpen={errorModalOpen}
        onRequestClose={() => { setErrorModalOpen(false) }}
        style={{
          content: {
            width: 400,
            height: 268,
            inset: "unset",
            padding: 0,
            border: "none",
            borderRadius: "16px",
            boxShadow:
              "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
            overflow: "hidden",
          },
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.55)",
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className='flex flex-col items-center justify-center h-full gap-4 p-6'>
          <Error />
          <div className='text-center'>
            <div className='text-2xl font-semibold mb-1'>Email address and password do not match</div>
            <div className='text-base'>Please try again</div>
          </div>
          <Button type={ButtonType.PRIMARY} content="OK" onClick={async () => {
            setErrorModalOpen(false)
          }} />
        </div>
      </Modal>
      <div className='w-[540px] flex flex-col pt-[100px] items-center'>
        <div className='text-[40px] font-bold'>Sign in</div>
        <div className='flex flex-col items-center mt-10 gap-6 w-full mb-8'>
          <div className='flex flex-col items-stretch gap-2 w-full'>
            <div className='text-base font-bold pl-6'>{"Email (Required)"}</div>
            <Input value={email} isError={false} onChange={(e) => {
              setEmail((e.target as HTMLInputElement).value)
            }} />
          </div>
          <div className='flex flex-col items-stretch gap-2 w-full'>
            <div className='text-base font-bold pl-6'>{"Password (Required)"}</div>
            <Input value={password} isError={false} type="password" onChange={(e) => {
              setPassword((e.target as HTMLInputElement).value)
            }} />
          </div>
        </div>
        <div className='flex gap-4 pb-[100px]'>
          <Button type={ButtonType.SECONDARY} loading={loading} content="Sign up" onClick={() => { navigate("/register") }} />
          <Button type={ButtonType.PRIMARY} loading={loading} disabled={!enableLogin} content="Sign in" onClick={async () => {
            await mutateAsync({
              username: email,
              password,
              isReviewManagement: true
            })
          }} />
        </div>
      </div>
    </div>
  );
}

export default ReviewManagementLogin;
