import { useMutation } from '@tanstack/react-query'
import { queryClient } from '../App'
import useAgentInfoStore from '../store/agentInfo'
import { axiosWithAuth } from '../utils/axios'

export const useUpdateAgent = () => {
  const axios = axiosWithAuth()
  const { agentId, name, instruction, openMessage, knowledges } =
    useAgentInfoStore()
  const updateAgent = async () => {
    try {
      const data = {
        id: agentId,
        name,
        instruction,
        open_message: openMessage,
        knowledges,
      }

      const res = await axios.patch('agent', data)
      queryClient.setQueryData(
        ['agentDetail', agentId.toString()],
        (data: any) => {
          const newData = {
            ...data,
            name,
            instruction,
            open_message: openMessage,
            knowledges,
          }
          return newData
        },
      )
      return res
    } catch (e) {
      return null
    } finally {
    }
  }

  const { mutateAsync, isLoading } = useMutation(updateAgent)
  return { mutateAsync, isLoading }
}
