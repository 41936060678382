import React, { useEffect, useState } from 'react'
import Navbar from './shared/Navbar'
import img1 from '../static/HomeIMG1.svg'
import img2 from '../static/HomeIMG2.svg'
import img3 from '../static/HomeIMG3.svg'
import { Helmet } from 'react-helmet'
import ChatBot from '../chatbot/ChatBot'

const DARK_OFFSET = 288

function Website() {
  const [isDark, setIsDark] = useState(true)
  useEffect(() => {
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop
    if (scrollPosition >= DARK_OFFSET) {
      setIsDark(false)
    }
    console.log(scrollPosition)
    const handleScroll = () => {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop
      console.log(scrollPosition, scrollPosition >= DARK_OFFSET)
      if (scrollPosition >= DARK_OFFSET) {
        setIsDark(false)
      } else {
        setIsDark(true)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Maximize Customer Satisfaction and Minimize Service Costs with Service
          Genie AI: Your Guide to Enhancing Customer Experience Using AI Agents
        </title>
        <link rel="canonical" href="https://servicegenie.ai/" />
        <meta
          name="description"
          content="how to use the AI agent to reduce your customer service cost and improve the customer experiences? Service Genie AI platform can help you to achieve 100% customer satisfaction with 20% of cost"
        ></meta>
      </Helmet>
      <Navbar isDark={isDark} contactPos={2066} />
      <div className="flex flex-col min-w-[1000px] w-full">
        <div
          style={{
            backgroundImage: isDark ? "url(https://servicegenie.ai/HeroBG.svg)" : undefined,
          }}
          className="flex flex-col items-center text-white text-center gap-12 px-[60px] bg-cover bg-no-repeat bg-top w-full"
        >
          <p className="pt-[60px] text-6xl font-extrabold">
            AI-powered customer service platform
          </p>
          <p className="pb-[60px] text-[40px]">
            <span className="text-[#56A6FF] font-extrabold">100%</span>
            <span className="text-white font-light"> customer delight at </span>
            <span className="text-[#56A6FF] font-extrabold">20%</span>
            <span className="text-white font-light"> cost</span>
          </p>
        </div>
        <div className="flex flex-col px-[60px] pt-[60px] pb-[100px]">
          <div className="flex flex-col gap-8">
            <div className="w-full bg-gray-50 rounded-3xl py-[80px] pl-[60px] pr-6 flex items-center gap-[60px]">
              <div className="flex flex-col gap-10">
                <p className="text-[40px] font-extrabold">
                  Uphold a stellar reputation on Google Maps and Yelp
                </p>
                <p className="text-[28px]">
                  <span className="text-gray-900">Generate </span>
                  <span className="text-[#56A6FF] font-semibold">
                    thoughtful, customized responses
                  </span>
                  <span className="text-gray-900">
                    {' '}
                    to online reviews with AI
                  </span>
                </p>
              </div>
              <img src={img2} />
            </div>
            <div className="w-full bg-gray-50 rounded-3xl py-[80px] pl-[60px] pr-6 flex items-center gap-[60px]">
              <div className="flex flex-col gap-10">
                <p className="text-[40px] font-extrabold">
                  In-depth insights to truly understand your customers
                </p>
                <p className="text-[28px]">
                  <span className="text-gray-900">Beyond just metrics: </span>
                  <span className="text-[#56A6FF] font-semibold">
                    actionable steps
                  </span>
                  <span className="text-gray-900">
                    {' '}
                    to advance your business
                  </span>
                </p>
              </div>
              <img src={img3} />
            </div>
            <div className="w-full bg-gray-50 rounded-3xl py-[80px] pl-[60px] pr-6 flex items-center gap-[60px]">
              <div className="flex flex-col gap-10">
                <p className="text-[40px] font-extrabold">
                  Respond to customer phone calls with an AI voice agent
                </p>
                <p className="text-[28px]">
                  <span className="text-gray-900">Manage </span>
                  <span className="text-[#56A6FF] font-semibold">
                    reservations
                  </span>
                  <span className="text-gray-900">, process online </span>
                  <span className="text-[#56A6FF] font-semibold">orders</span>
                  <span className="text-gray-900">, address </span>
                  <span className="text-[#56A6FF] font-semibold">
                    complaints
                  </span>
                  <span className="text-gray-900"> and </span>
                  <span className="text-[#56A6FF] font-semibold">
                    questions
                  </span>
                </p>
              </div>
              <img className="px-[65px]" src={img1} />
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-6 px-[60px] pb-[100px]">
          <div className="p-5 flex flex-col gap-6 border border-solid border-gray-200 rounded-3xl flex-[1_1_40%] lg:flex-[1_1_20%]">
            <div className="text-[32px] font-extrabold">Always on</div>
            <div className="text-[24px] font-normal">
              Serve your customers 24/7 with instantaneous responses
            </div>
          </div>
          <div className="p-5 flex flex-col gap-6 border border-solid border-gray-200 rounded-3xl flex-[1_1_40%] lg:flex-[1_1_20%]">
            <div className="text-[32px] font-extrabold">Customization</div>
            <div className="text-[24px] font-normal">
              Adapting to your business, from selling coffee to houses
            </div>
          </div>
          <div className="p-5 flex flex-col gap-6 border border-solid border-gray-200 rounded-3xl flex-[1_1_40%] lg:flex-[1_1_20%]">
            <div className="text-[32px] font-extrabold">Save big</div>
            <div className="text-[24px] font-normal">
              Cut costs by 80% versus conventional human labor
            </div>
          </div>
          <div className="p-5 flex flex-col gap-6 border border-solid border-gray-200 rounded-3xl flex-[1_1_40%] lg:flex-[1_1_20%]">
            <div className="text-[32px] font-extrabold">Scalability</div>
            <div className="text-[24px] font-normal">
              Support your business growth from 1 to 1000+
            </div>
          </div>
        </div>
        <div className="p-[60px] bg-[#6AA9FF33] flex items-start gap-[60px]">
          <div className="flex flex-col gap-8 grow-0">
            <p className="text-[40px] font-extrabold">
              Explore how ServiceGenie can work for you
            </p>
            <p className="text-2xl">
              <span className="text-gray-900">ServiceGenie has empowered </span>
              <span className="text-[#56A6FF] font-semibold">
                food & beverage
              </span>
              <span className="text-gray-900"> chains, </span>
              <span className="text-[#56A6FF] font-semibold">e-commerce</span>
              <span className="text-gray-900"> platforms, and </span>
              <span className="text-[#56A6FF] font-semibold">real estate</span>
              <span className="text-gray-900">
                {' '}
                businesses with cutting-edge AI, facilitating improved
                efficiency and growth.
              </span>
            </p>
          </div>
          <div className="grow">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSfy-wkRHUUSpmMBz7U-Mmi5mIsi8K3otMZGMT2osOSkIsztgw/viewform?embedded=true"
              width={588}
              height={946}
              frameBorder="0"
              marginHeight={0}
              marginWidth={0}
            >
              Loading…
            </iframe>
          </div>
        </div>
        <div className="px-[60px] py-7 font-bold">
          © 2024 ServiceGenie, Inc.
        </div>
      </div>
    </div>
  )
}

export default Website
