import classNames from 'classnames'
import { MoonLoader } from 'react-spinners'
import { DateRangePicker, Rate, SelectPicker } from 'rsuite'
import ReviewNavbar from '../components/ReviewNavbar'
import { useReport } from '../query/useReport'
import {
  SummarySort,
  SummarySource,
  TimeRange,
  useSummaryFilterStore,
} from '../store/filter'
import { ReactComponent as Sort } from '../static/Sort.svg'
import { useEffect } from 'react'
import { ReactComponent as Selected } from '../static/Selected.svg'


function StatisticalReport() {
  const { data: reportData, isFetching, isLoading } = useReport()
  const today = new Date()
  const dayOfWeek = today.getDay()

  const daysToLastMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1) + 7
  const lastMonday = new Date(today)
  lastMonday.setDate(today.getDate() - daysToLastMonday)

  const lastSunday = new Date(lastMonday)
  lastSunday.setDate(lastMonday.getDate() + 6)
  const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
  const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0)
  function formatDate(date: Date) {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
  }
  console.log("report data is:", reportData)
  const maxFeedback = reportData
    ? Math.max(
      ...reportData?.flatMap(([_, { feedback_type_count }]: any) =>
        Object.values(feedback_type_count),
      ),
    )
    : 1

  const {
    timeRange,
    setTimeRange,
    sortMethod,
    setSortMethod,
    source,
    setSource,
    startAt,
    setStartAt,
    endAt,
    setEndAt,
  } = useSummaryFilterStore()
  useEffect(() => {
    if (timeRange === TimeRange.PAST_WEEK) {
      setStartAt(lastMonday)
      setEndAt(lastSunday)
    } else if (timeRange === TimeRange.PAST_MONTH) {
      setStartAt(lastMonth)
      setEndAt(endOfLastMonth)
    }
  }, [timeRange])
  return (
    <div className="flex justify-center pt-[100px] pb-5 h-full min-h-0">
      <ReviewNavbar />
      <div className="flex flex-col items-center w-[1024px] min-h-[800px] pb-5">
        <div className="flex items-center justify-between mb-10 w-full px-6">
          <div className="text-[40px] font-bold">Statistical report</div>
        </div>
        <div className="flex flex-col rounded-3xl w-full border-[1px] border-solid border-gray-200 shrink min-h-0">
          <div className="statistical-report-filter flex items-start justify-between w-full p-6 border-b border-b-gray-200">
            <div className="flex items-start gap-4">
              <div className="flex flex-col gap-2">
                <SelectPicker
                  value={timeRange}
                  onChange={(value) => {
                    if (value !== null) {
                      setTimeRange(value)
                    }
                  }}
                  menuClassName='popup'
                  placeholder="Time range"
                  cleanable={false}
                  data={[
                    {
                      label: `Past week (${formatDate(lastMonday)} - ${formatDate(lastSunday)})`,
                      value: TimeRange.PAST_WEEK,
                    },
                    {
                      label: `Past month (${formatDate(lastMonth)} - ${formatDate(endOfLastMonth)})`,
                      value: TimeRange.PAST_MONTH,
                    },
                    {
                      label: 'Choose date by yourself',
                      value: TimeRange.CUSTOM_DATE,
                    },
                  ]}
                  style={{ width: 280 }}
                  searchable={false}
                  renderMenuItem={(label, item) => {
                    return <div className='flex items-center gap-2.5'>
                      {
                        item.value === timeRange ? <Selected className='fill-blue-500' /> : <div className='h-6 w-6' />
                      }
                      {label}
                    </div>
                  }}
                />
                {timeRange === TimeRange.CUSTOM_DATE && (
                  <DateRangePicker
                    format="MM/dd/yyyy"
                    cleanable={false}
                    ranges={[]}
                    character=" – "
                    caretAs={null}
                    value={[startAt, endAt]}
                    onChange={(value) => {
                      if (value) {
                        setStartAt(value[0])
                        setEndAt(value[1])
                      }
                    }}
                  />
                )}
              </div>
              <SelectPicker
                value={source}
                onChange={(value) => {
                  if (value !== null) {
                    setSource(value)
                  }
                }}
                placeholder="Source"
                searchable={false}
                cleanable={false}
                data={[
                  {
                    label: 'Google Maps',
                    value: SummarySource.GOOGLE_MAP,
                  },
                ]}
                style={{ width: 200 }}
                menuClassName='popup'
                renderMenuItem={(label, item) => {
                  return <div className='flex items-center gap-2.5'>
                    {
                      item.value === source ? <Selected className='fill-blue-500' /> : <div className='h-6 w-6' />
                    }
                    {label}
                  </div>
                }}
              />
            </div>
            <SelectPicker
              value={sortMethod}
              onChange={(value) => {
                if (value !== null) {
                  setSortMethod(value)
                }
              }}
              placeholder="Sort"
              label={<Sort style={{ marginRight: 10 }} />}
              cleanable={false}
              data={[
                {
                  label: 'Most reviews first',
                  value: SummarySort.MOST_REVIEW,
                },
                {
                  label: 'Highest rating first',
                  value: SummarySort.HIGHEST_RATING,
                },
              ]}
              style={{ width: 220 }}
              searchable={false}
              menuClassName='popup'
              renderMenuItem={(label, item) => {
                return <div className='flex items-center gap-2.5'>
                  {
                    item.value === sortMethod ? <Selected className='fill-blue-500' /> : <div className='h-6 w-6' />
                  }
                  {label}
                </div>
              }}
            />
          </div>

          <div className="overflow-auto border-collapse w-full min-h-[320px]">
            {isFetching || isLoading ? (
              <div className="flex items-center justify-center w-full h-full">
                <MoonLoader size={24} />
              </div>
            ) : (
              reportData?.map((data: any, index: number) => {
                const storeName = data[0]
                const storeSummary = data[1]
                return (
                  <div
                    className={classNames(
                      'flex items-center gap-10 h-20 px-5 py-6 [&:not(:last-child)]:border-b border-b-gray-200 cursor-pointer',
                    )}
                    onClick={() => {
                      window.open(`store-report/${encodeURIComponent(storeName)}`)
                    }}
                  >
                    <div className="font-bold w-[140px]">{storeName}</div>
                    <div className="flex items-center gap-3">
                      <div>{storeSummary.average_star}</div>
                      <Rate
                        value={parseFloat(storeSummary.average_star)}
                        readOnly
                        color="yellow"
                        allowHalf
                        size="sm"
                      />
                    </div>
                    <div className="flex flex-col grow gap-2">
                      <div className="flex items-center gap-2 w-full" style={{ width: `${storeSummary.feedback_type_count.Positive * 100 / maxFeedback}%` }}>
                        <div className="h-2 rounded-3xl grow bg-green-600" />
                        <div className="text-xs text-green-600">
                          {storeSummary.feedback_type_count.Positive}
                        </div>
                      </div>
                      <div className="flex items-center gap-2 w-full" style={{ width: `${storeSummary.feedback_type_count.Negative * 100 / maxFeedback}%` }}>
                        <div className="h-2 rounded-3xl grow bg-red-700" />
                        <div className="text-xs text-red-700">
                          {storeSummary.feedback_type_count.Negative}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            )}
          </div>
          <div className="flex items-center justify-end w-full p-6 border-t border-t-gray-200">
            <div className="flex items-center gap-6 justify-end">
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-3xl bg-green-600" />
                <div className="text-green-600 text-xs">Positive reviews</div>
              </div>
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-3xl bg-red-700" />
                <div className="text-red-700 text-xs">Negative reviews</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatisticalReport
