type Props = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  disabled?: boolean
  loading?: boolean
  onClick: () => void
}

function IconButton(props: Props) {
  const { Icon, onClick, disabled, loading } = props

  if (disabled) {
    return (
      <div className="cursor-not-allowed">
        <Icon className="fill-gray-200" />
      </div>
    )
  } else {
    return (
      <div className="cursor-pointer" onClick={onClick}>
        <Icon className="fill-gray-700" />
      </div>
    )
  }
}

export default IconButton
