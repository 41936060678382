import React, { useEffect, useState } from 'react'
import Button, { ButtonType } from '../components/Button'
import { ReactComponent as Add } from '../static/Add.svg'
import AgentList from '../components/AgentList'
import { ReactComponent as File } from '../static/File.svg'
import { MoonLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { useAgentDetail } from '../query/useAgentDetail'
import useAgentInfoStore, {
  Agent,
  FileStatus,
  getTextForStatus,
  Knowledge,
} from '../store/agentInfo'
import Modal from 'react-modal'
import { useDeleteAgent } from '../query/useDeleteAgent'
import { ReactComponent as Warning } from '../static/Warning.svg'
import { ReactComponent as Error } from '../static/Error.svg'
import { ReactComponent as Success } from '../static/Success.svg'
import { ReactComponent as Delete } from '../static/Delete.svg'
import { ReactComponent as Download } from '../static/Download.svg'
import Input from '../components/Input'
import TextArea from '../components/TextArea'
import Upload from '../components/Upload'
import { useUploadFile } from '../query/useUploadFile'
import { useUpdateAgent } from '../query/useUpdateAgent'
import { useConversationHistoryByAgent } from '../query/useConversationHistory'
import { ReactComponent as OpenTab } from '../static/OpenTab.svg'
import Navbar from '../components/Navbar'
import classNames from 'classnames'

function AgentDetail() {
  const { agentId } = useParams()
  const { data, isFetching, isLoading, refetch } = useAgentDetail(agentId)
  const {
    name,
    setName,
    instruction,
    setInstruction,
    openMessage,
    setOpenMessage,
    knowledges,
    addToKnowledge,
    removeKnowledge,
    initForUpdate,
    successModalOpen,
    setSuccessModalOpen,
    errorModalOpen,
    setErrorModalOpen,
  } = useAgentInfoStore()
  const agentInfo = data as Agent
  const navigate = useNavigate()
  const { mutateAsync } = useDeleteAgent()
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const enableUpdate =
    Boolean(name) && Boolean(instruction) && Boolean(openMessage)
  const { mutateAsync: uploadFile } = useUploadFile()
  const { mutateAsync: updateAgent, isLoading: isUpdating } = useUpdateAgent()
  const [uploading, setUploading] = useState(false)
  let timeout: any = null

  useEffect(() => {
    if (isEditing) {
      initForUpdate(agentInfo)
    }
  }, [isEditing])

  useEffect(() => {
    const knowledges = data?.knowledges
    if (knowledges) {
      if (
        knowledges.some(
          (knowledge: Knowledge) => knowledge.status === FileStatus.PROCESSING,
        )
      ) {
        if (timeout) {
          clearTimeout(timeout)
          timeout = setTimeout(refetch, 5000)
        } else {
          timeout = setTimeout(refetch, 5000)
        }
      }
    }
  }, [data])

  if (typeof agentInfo !== 'object') {
    return null
  }

  if (isEditing) {
    return (
      <div className="flex justify-center pt-[156px] pb-[200px]">
        <Navbar />
        <Modal
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          isOpen={successModalOpen}
          onRequestClose={() => {
            setSuccessModalOpen(false)
          }}
          style={{
            content: {
              width: 400,
              height: 200,
              inset: 'unset',
              padding: 0,
              border: 'none',
              borderRadius: '16px',
              boxShadow:
                '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
              overflow: 'hidden',
            },
            overlay: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.55)',
              zIndex: 100,
            },
          }}
          htmlOpenClassName="overflow-hidden"
          shouldCloseOnEsc={true}
        >
          <div className="flex flex-col items-center justify-center h-full gap-4 p-6">
            <Success />
            <div className="text-2xl font-semibold">Agent created</div>
            <Button
              type={ButtonType.PRIMARY}
              content="OK"
              onClick={async () => {
                setSuccessModalOpen(false)
                navigate('/agents')
              }}
            />
          </div>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          isOpen={errorModalOpen}
          onRequestClose={() => {
            setErrorModalOpen(false)
          }}
          style={{
            content: {
              width: 400,
              height: 256,
              inset: 'unset',
              padding: 0,
              border: 'none',
              borderRadius: '16px',
              boxShadow:
                '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
              overflow: 'hidden',
            },
            overlay: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.55)',
              zIndex: 100,
            },
          }}
          htmlOpenClassName="overflow-hidden"
          shouldCloseOnEsc={true}
        >
          <div className="flex flex-col items-center justify-center h-full gap-4 p-6">
            <Error />
            <div className="text-2xl font-semibold">File cannot upload</div>
            <div className="text-base text-center">{`The file exceeds file size limit, please try a smaller file.`}</div>
            <Button
              type={ButtonType.PRIMARY}
              content="OK"
              onClick={async () => {
                setErrorModalOpen(false)
              }}
            />
          </div>
        </Modal>
        <div className="flex flex-col items-center w-[1024px] gap-6">
          <div className="flex items-center justify-start mb-[40px] w-full px-6 py-4">
            <div className="text-[40px] font-bold">Voice agent details</div>
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col gap-2 px-6 w-full">
              <div className="text-base font-bold">Voice agent name</div>
              <div className="text-base">Required, 250 characters max</div>
            </div>
            <Input
              value={name}
              isError={false}
              onChange={(e) => {
                setName((e.target as HTMLInputElement).value)
              }}
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col gap-2 px-6 w-full">
              <div className="text-base font-bold">Instructions</div>
              <div className="text-base">Required, 2000 characters max</div>
            </div>
            <TextArea
              value={instruction}
              isError={false}
              onChange={(e) => {
                setInstruction((e.target as HTMLInputElement).value)
              }}
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-col gap-2 px-6 w-full">
              <div className="text-base font-bold">Open message</div>
              <div className="text-base">Required, 250 characters max</div>
            </div>
            <Input
              value={openMessage}
              isError={false}
              onChange={(e) => {
                setOpenMessage((e.target as HTMLInputElement).value)
              }}
            />
          </div>
          <div className="flex flex-col w-full gap-4">
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Knowledge</div>
              <div className="text-base">
                {'20 files max, each file 20MB max (PDF 150MB max)'}
              </div>
            </div>
            <div className="flex flex-wrap gap-3">
              {knowledges.map((knowledge, index) => (
                <div className="w-[506px] bg-blue-50 px-6 py-3 flex items-center rounded-lg">
                  <div className="flex gap-2 items-center justify-start grow">
                    <File />
                    <div>{knowledge.name}</div>
                    <div
                      className={classNames('', {
                        'text-red-700 font-semibold':
                          knowledge.status === FileStatus.FAILED,
                      })}
                    >{`(${getTextForStatus(knowledge.status)})`}</div>
                  </div>
                  <div className="flex gap-6 items-center justify-start">
                    <Delete
                      className={classNames('cursor-pointer', {
                        'fill-blue-500': knowledge.status !== FileStatus.FAILED,
                        'fill-red-700': knowledge.status === FileStatus.FAILED,
                      })}
                      onClick={() => {
                        removeKnowledge(index)
                      }}
                    />
                    {knowledge.download_url !== '' && (
                      <Download
                        className={classNames('cursor-pointer', {
                          'fill-blue-500':
                            knowledge.status !== FileStatus.FAILED,
                          'fill-red-700':
                            knowledge.status === FileStatus.FAILED,
                        })}
                        onClick={async () => {
                          try {
                            const response = await fetch(knowledge.download_url)
                            const blob = await response.blob()
                            const link = document.createElement('a')
                            link.href = URL.createObjectURL(blob)
                            link.download = knowledge.name // Replace 'filename.ext' with your desired filename
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                          } catch (error) {
                            console.error('Error downloading file:', error)
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            {knowledges.length < 20 && (
              <Upload
                content="Upload knowledge file"
                onBeforeUpload={async (file) => {
                  if (file) {
                    if (
                      file.name.toLowerCase().endsWith('.pdf') ||
                      file.type === 'application/pdf'
                    ) {
                      if (file.size > 150 * 1024 * 1024) {
                        setErrorModalOpen(true)
                      } else {
                        setUploading(true)
                        await uploadFile(file)
                        setUploading(false)
                      }
                    } else {
                      if (file.size > 20 * 1024 * 1024) {
                        setErrorModalOpen(true)
                      } else {
                        setUploading(true)
                        await uploadFile(file)
                        setUploading(false)
                      }
                    }
                  }
                }}
                loading={uploading}
              />
            )}
          </div>

          <div className="fixed flex items-center justify-center w-full py-6 bottom-0 gap-4 border-t border-t-gray-200 bg-white">
            <Button
              disabled={uploading}
              type={ButtonType.SECONDARY}
              content="Discard changes"
              loading={isUpdating}
              onClick={() => {
                setIsEditing(false)
              }}
            />
            <Button
              type={ButtonType.PRIMARY}
              content="Save changes"
              disabled={!enableUpdate || uploading}
              loading={isUpdating}
              onClick={async () => {
                await updateAgent()
                setIsEditing(false)
                refetch()
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-center pt-[156px] pb-[200px]">
      <Navbar />
      <Modal
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        isOpen={warningModalOpen}
        onRequestClose={() => {
          setWarningModalOpen(false)
        }}
        style={{
          content: {
            width: 400,
            height: 312,
            inset: 'unset',
            padding: 0,
            border: 'none',
            borderRadius: '16px',
            boxShadow:
              '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
            overflow: 'hidden',
          },
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.55)',
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col items-center justify-center h-full gap-4 p-6">
          <Warning />
          <div className="text-2xl font-semibold text-center">{`Are you sure you want to delete ${agentInfo?.name}?`}</div>
          <div className="text-base text-center">{`You can’t undo this action. By deleting ${agentInfo?.name}, chat history with this agent will also be deleted.`}</div>
          <div className="flex gap-3">
            <Button
              type={ButtonType.SECONDARY}
              content="Cancel"
              onClick={async () => {
                setWarningModalOpen(false)
              }}
            />
            <Button
              type={ButtonType.PRIMARY}
              content="Delete agent"
              onClick={async () => {
                setWarningModalOpen(false)
                await mutateAsync({ agentId })
                navigate('/agents')
              }}
            />
          </div>
        </div>
      </Modal>
      <div className="flex flex-col items-center w-[1024px] gap-6">
        <div className="flex items-center justify-between mb-[40px] w-full px-6 py-4">
          <div className="text-[40px] font-bold">Voice agent details</div>
          <Button
            type={ButtonType.SECONDARY}
            content="Test agent"
            Icon={OpenTab}
            onClick={async () => {
              window.open(`/test/${agentId}`, '_blank')
            }}
          />
        </div>
        {isLoading ? (
          <div className="flex min-h-[200px] items-center">
            <MoonLoader size={24} />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Voice agent name</div>
              <div className="text-base">{agentInfo.name}</div>
            </div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Instructions</div>
              <div className="text-base">{agentInfo.instruction}</div>
            </div>
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Open message</div>
              <div className="text-base">{agentInfo.open_message}</div>
            </div>
            <div className="flex flex-col w-full gap-4">
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">Knowledge</div>
                <div className="text-base">20 files max, each file 10MB max</div>
              </div>
              <div className="flex flex-wrap gap-3">
                {agentInfo.knowledges.map((knowledge) => (
                  <div className="w-[506px] bg-blue-50 px-6 py-3 flex items-center rounded-lg">
                    <div className="flex w-full items-center justify-between">
                      <div className="flex gap-2">
                        <File />
                        <div>{knowledge.name}</div>
                        <div
                          className={classNames('', {
                            'text-red-700 font-semibold':
                              knowledge.status === FileStatus.FAILED,
                          })}
                        >{`(${getTextForStatus(knowledge.status)})`}</div>
                      </div>
                      {knowledge.download_url !== '' && (
                        <Download
                          className={classNames('cursor-pointer', {
                            'fill-blue-500':
                              knowledge.status !== FileStatus.FAILED,
                            'fill-red-700':
                              knowledge.status === FileStatus.FAILED,
                          })}
                          onClick={async () => {
                            try {
                              const response = await fetch(
                                knowledge.download_url,
                              )
                              const blob = await response.blob()
                              const link = document.createElement('a')
                              link.href = URL.createObjectURL(blob)
                              link.download = knowledge.name // Replace 'filename.ext' with your desired filename
                              document.body.appendChild(link)
                              link.click()
                              document.body.removeChild(link)
                            } catch (error) {
                              console.error('Error downloading file:', error)
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <div className="text-base font-bold px-6">Phone number</div>
              <div className="text-base flex flex-col gap-1">
                {agentInfo.phone_numbers.length === 0 && (
                  <div className="bg-yellow-50 h-[54px] flex items-center justify-start rounded-lg px-6">
                    Agent Phone number not assigned. Please contact admin to get
                    an agent phone number.
                  </div>
                )}
                {agentInfo.phone_numbers.map((phone) => (
                  <div className="px-6">{phone}</div>
                ))}
              </div>
            </div>
          </>
        )}
        <div className="fixed flex items-center justify-center w-full py-6 bottom-0 gap-4 border-t border-t-gray-200 bg-white">
          <Button
            type={ButtonType.SECONDARY}
            content="Delete agent"
            onClick={() => {
              setWarningModalOpen(true)
            }}
          />
          <Button
            type={ButtonType.PRIMARY}
            content="Edit agent"
            onClick={async () => {
              setIsEditing(true)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default AgentDetail
