import React, { useEffect, useRef, useState } from 'react'
import { MoonLoader } from 'react-spinners'
import IconButton from '../components/IconButton'
import ReviewNavbar from '../components/ReviewNavbar'
import Select from '../components/Select'
import Tag from '../components/Tag'
import { Feedback, useFeedbacks } from '../query/useFeedbacks'
import { ReactComponent as Left } from '../static/Left.svg'
import { ReactComponent as Right } from '../static/Right.svg'
import { ReactComponent as SendMessage } from '../static/SendMessage.svg'
import { ReactComponent as Close } from '../static/Close.svg'
import customerAvatar from '../static/CustomerAvatar.svg'
import { PAGE_LIMIT } from '../utils/const'
import Button, { ButtonType } from '../components/Button'
import TextArea from '../components/TextArea'
import { useIgnoreFeedback, useReplyFeedback } from '../query/usePostFeedback'
import classNames from 'classnames'

function ReplyCustomerReview() {
  const [offset, setOffset] = useState(0)
  const [shouldScroll, setShouldScroll] = useState(false)
  const [isBackgroundRefetch, setIsBackgroundRefetch] = useState(false)
  const { data, isFetching, isLoading, isFetched, refetch, isRefetching } =
    useFeedbacks(offset, isBackgroundRefetch, setIsBackgroundRefetch)
  const [selectedFeedbackIndex, setSelectedFeedbackIndex] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [reply, setReply] = useState('')
  const [isCorrected, setIsCorrected] = useState(false)
  const { mutateAsync: ignoreFeedback } = useIgnoreFeedback()
  const { mutateAsync: replyFeedback } = useReplyFeedback()
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const getDate = (created_at: string) => {
    const date = new Date(created_at)
    if (date.getDate() === new Date().getDate()) {
      return date.toLocaleTimeString()
    } else {
      return date.toDateString()
    }
  }
  const feedbackData = data?.feedbacks?.filter(
    (feedback: Feedback) => feedback.status === 'Generated',
  )
  const selectedFeedbackId = feedbackData?.[selectedFeedbackIndex]?.id
  const selectedFeedback: Feedback = feedbackData?.find(
    (feedback: Feedback) => feedback.id == selectedFeedbackId,
  )

  useEffect(() => {
    if (selectedFeedbackIndex >= 0 && shouldScroll) {
      itemsRef?.current[selectedFeedbackIndex]?.scrollIntoView()
      setShouldScroll(false)
    }
  }, [selectedFeedbackIndex, shouldScroll])

  useEffect(() => {
    if (selectedFeedbackId >= 0) {
      setReply(selectedFeedback?.generated_response)
      setIsCorrected(false)
    }
  }, [selectedFeedbackId])

  useEffect(() => {
    const interval = setInterval(() => {
      //setIsBackgroundRefetch(true)
      //refetch()
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (!data || selectedFeedbackIndex === -1) {
      return
    }
    else if (offset + 1 > data.count) {
      setOffset((value) => Math.max(0, value - PAGE_LIMIT))
      setSelectedFeedbackIndex(0)
      setShouldScroll(true)
    }
    else if (selectedFeedbackIndex >= data.feedbacks.length) {
      setSelectedFeedbackIndex(data.feedbacks.length - 1)
      setShouldScroll(true)
    } else {
      itemsRef?.current[selectedFeedbackIndex]?.scrollIntoView()
    }
  }, [data])
  return (
    <div className="flex justify-center pt-[100px] pb-5 h-full min-h-0">
      <ReviewNavbar />
      <div className="flex flex-col items-center w-[1024px] min-h-[800px] pb-5">
        <div className="flex items-center justify-between mb-10 w-full px-6">
          <div className="text-[40px] font-bold">Reply customer review</div>
        </div>
        <div className="flex flex-col rounded-3xl w-full border-[1px] border-solid border-gray-200 shrink min-h-0">
          <div className="flex items-center justify-between w-full p-6 border-b border-b-gray-200">
            <div className="flex items-center gap-4">
              <Select content="All stores" />
              <Select content="Unread" />
              <Select content="Negative" />
            </div>
            <Select content="Latest first" />
          </div>
          {selectedFeedbackIndex < 0 ? (
            <div className="overflow-auto border-collapse w-full min-h-[520px]">
              {(isFetching || isLoading) && !isBackgroundRefetch ? (
                <div className="flex items-center justify-center w-full h-full">
                  <MoonLoader size={24} />
                </div>
              ) : (
                feedbackData?.map((feedback: Feedback, index: number) => (
                  <div
                    className={classNames(
                      'px-6 py-4 flex flex-col gap-2 w-full border-solid border-b-gray-200 border-b cursor-pointer',
                      {
                        'bg-gray-50': index === selectedFeedbackIndex,
                      },
                    )}
                    onClick={() => {
                      setSelectedFeedbackIndex(index)
                      setShouldScroll(true)
                    }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex gap-2">
                        {feedback.feedback_type === 'Positive' ? (
                          <Tag type="positive" />
                        ) : feedback.complaint_severity === 'Mild' ? (
                          <Tag type="mild" />
                        ) : (
                          <Tag type="severe" />
                        )}
                        {feedback.complaint_category.map((category) => (
                          <Tag type="normal" content={category} />
                        ))}
                      </div>
                      {`${feedback.store_name}  |  ${getDate(feedback.feedback_created_at)}`}
                    </div>
                    <div className="font-bold whitespace-nowrap text-ellipsis w-full overflow-hidden">{`${feedback.customer_name}: ${feedback.feedback_content}`}</div>
                    <div className="whitespace-nowrap text-ellipsis w-full overflow-hidden">
                      {feedback.generated_response}
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : (isFetching || isLoading) && !isBackgroundRefetch ? (
            <div className="flex min-h-[520px] items-center justify-center w-full">
              <MoonLoader size={24} />
            </div>
          ) : (
            <div className="flex min-w-0 min-h-0">
              <div className="overflow-auto border-collapse min-w-[340px] w-[340px] h-full border-gray-200 border-r border-solid">
                {feedbackData?.map((feedback: Feedback, index: number) => (
                  <div
                    className={classNames(
                      'px-6 py-4 flex flex-col gap-2 border-solid border-gray-200 border-b cursor-pointer',
                      {
                        'bg-gray-50': index === selectedFeedbackIndex,
                      },
                    )}
                    onClick={() => {
                      setSelectedFeedbackIndex(index)
                    }}
                    ref={el => itemsRef.current[index] = el!}
                  >
                    <div className="flex items-center w-full gap-6">
                      {feedback.feedback_type === 'Positive' ? (
                        <div className="min-w-6 min-h-6 rounded-full bg-green-600 grow" />
                      ) : feedback.complaint_severity === 'Mild' ? (
                        <div className="min-w-6 min-h-6 rounded-full bg-yellow-500 grow" />
                      ) : (
                        <div className="min-w-6 min-h-6 rounded-full bg-red-700 grow" />
                      )}
                      <div className="flex flex-col min-w-0">
                        <div className="font-bold whitespace-nowrap text-ellipsis w-full overflow-hidden">{`${feedback.customer_name}: ${feedback.feedback_content}`}</div>
                        <div className="whitespace-nowrap text-ellipsis w-full overflow-hidden">
                          {feedback.generated_response}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex flex-col h-full min-h-0">
                <div className="flex flex-col p-6 gap-6 border-solid border-gray-200 border-b overflow-auto">
                  <div className="flex justify-between items-center">
                    <div className="flex gap-2">
                      {selectedFeedback?.complaint_category.map((category) => (
                        <Tag type="normal" content={category} />
                      ))}
                    </div>
                    <Close
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedFeedbackIndex(-1)
                      }}
                    />
                  </div>
                  <div className="flex gap-3 items-center">
                    <img className="w-10 h-10" src={customerAvatar} />
                    <div className="flex flex-col">
                      <div className="font-bold">
                        {selectedFeedback?.customer_name}
                      </div>
                      <div>{`${new Date(selectedFeedback?.feedback_created_at).toLocaleString()} | ${selectedFeedback?.store_name} | ${selectedFeedback?.social_app_name} | Rating: ${selectedFeedback?.star}`}</div>
                    </div>
                  </div>
                  <div className="px-4 py-6 rounded-lg bg-gray-50">
                    {selectedFeedback?.feedback_content}
                  </div>
                </div>
                <div className="flex flex-col p-6 gap-4 grow">
                  <div className="font-bold">AI generated reply:</div>
                  <TextArea
                    fixHeight={false}
                    value={reply}
                    isError={false}
                    onChange={(e) => {
                      setIsCorrected(true)
                      setReply((e.target as HTMLInputElement).value)
                    }}
                  />
                  <div className="flex gap-4 justify-end">
                    <Button
                      type={ButtonType.SECONDARY}
                      content="Ignore"
                      loading={loading}
                      onClick={async () => {
                        await ignoreFeedback({ id: selectedFeedbackId, offset })
                        refetch()
                        //setShouldScroll(true)
                      }}
                    />
                    <Button
                      type={ButtonType.PRIMARY}
                      Icon={SendMessage}
                      content="Post reply"
                      loading={loading}
                      onClick={async () => {
                        await replyFeedback({
                          id: selectedFeedbackId,
                          reply,
                          isCorrected,
                          offset,
                        })
                        refetch()
                        //setShouldScroll(true)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="flex items-center justify-end w-full p-6 border-t border-t-gray-200">
            <div className="flex items-center gap-3">
              {data && (!isFetching && !isLoading || isBackgroundRefetch) && `${Math.min(data.count, offset + 1)}  -  ${Math.min(offset + PAGE_LIMIT, data?.count)} of ${data?.count}`}
              <IconButton
                Icon={Left}
                disabled={offset === 0}
                onClick={() => {
                  setOffset((value) => value - PAGE_LIMIT)
                  if (selectedFeedbackIndex !== -1) {
                    setSelectedFeedbackIndex(0)
                  }
                }}
              />
              <IconButton
                Icon={Right}
                disabled={!data || offset + PAGE_LIMIT >= data?.count}
                onClick={() => {
                  setOffset((value) => value + PAGE_LIMIT)
                  if (selectedFeedbackIndex !== -1) {
                    setSelectedFeedbackIndex(0)
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReplyCustomerReview
