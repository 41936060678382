import { MoonLoader } from 'react-spinners';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../components/Navbar';
import ConversationList from '../components/ConversationList';
import { useConversationHistoryByAgent } from '../query/useConversationHistory';


function History() {
  const { agentId } = useParams()
  console.log('debug:', agentId)
  const { data, isFetching, isLoading, isFetched } = useConversationHistoryByAgent(agentId)
  return (
    <div className='flex justify-center pt-[156px] pb-[100px]'>
      <Navbar />
      <div className='flex flex-col items-center w-[1024px]'>
        <div className='flex items-center justify-between mb-[60px] w-full px-6 py-4'>
          <div className='text-[40px] font-bold'>Chat history list</div>
        </div>
        {isFetching || isLoading ? <div className='flex min-h-[200px] items-center'><MoonLoader size={24} /></div> : <ConversationList conversations={data} />}
      </div>
    </div>
  );
}

export default History;
