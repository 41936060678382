import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Navbar from '../components/Navbar'
import agentAvatar from '../static/AgentAvatar.svg'
import customerAvatar from '../static/CustomerAvatar.svg'
import { useAgentDetail } from '../query/useAgentDetail'
import Button, { ButtonType } from '../components/Button'
import { axiosWithAuth } from '../utils/axios'
import Cookies from 'js-cookie'
import TextArea from '../components/TextArea'
import { MoonLoader, PulseLoader, SyncLoader } from 'react-spinners'
import ReactMarkdown from 'react-markdown'
import { TextWithLinks } from '../components/TextWithLink'
import remarkGfm from 'remark-gfm'
import {
  Question,
  useHouseReport,
  useRegenerateReportQuestion,
  useShareToken,
} from '../query/useHouseReport'
import { Message, useToaster } from 'rsuite'
import { queryClient } from '../App'
import { useAgentApiKey } from '../query/useAgentApiKey'

export type Sentence = {
  role: 'human' | 'ai'
  content: string
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

function Test() {
  const { agentId } = useParams()
  const { data } = useAgentDetail(agentId)
  const { data: apiKey } = useAgentApiKey(agentId)
  const [chatHistory, setChatHistory] = useState<Sentence[]>([])
  const [conversationId, setConversationId] = useState(crypto.randomUUID())
  const [instruction, setInstruction] = useState('')
  const scrollRef = useRef<HTMLDivElement>(null)
  const { data: token } = useShareToken(agentId)
  console.log(apiKey)
  const addToHistory = (content: Sentence[]) =>
    setChatHistory((history) => {
      return [...history, ...content]
    })
  useEffect(() => {
    if (data) {
      setChatHistory([{ role: 'ai', content: data?.open_message }])
    }
  }, [data])

  const LinkRenderer = ({ node, ...props }: any) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  )

  const handleSubmit = async () => {
    if (scrollRef.current) {
      scrollRef.current.style.overflow = 'hidden'
    }
    const text = instruction.toString()
    setInstruction('')
    addToHistory([
      { role: 'human', content: text },
      { role: 'ai', content: '' },
    ])
    const token = Cookies.get('auth')
    const response = await fetch(
      'https://agent-management.onrender.com/agent_chat',
      {
        method: 'POST',
        body: JSON.stringify({
          agent_id: parseInt(agentId ?? ''),
          user_input: text,
          chat_history: chatHistory,
          conversation_id: conversationId,
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: token ? `Bearer ${token}` : '',
        },
      },
    )
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
    const reader = response.body
      ?.pipeThrough(new TextDecoderStream())
      .getReader()
    if (reader) {
      while (true) {
        const { value, done } = await reader.read()
        if (done) break
        setChatHistory((history) => {
          console.log('check', value)
          const newHistory = [...history]
          if (process.env.NODE_ENV === 'development') {
            console.log('check')
            if (!newHistory[newHistory.length - 1].content.endsWith(value)) {
              newHistory[newHistory.length - 1].content += value
            }
          } else {
            newHistory[newHistory.length - 1].content += value
          }
          return newHistory
        })
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        }
        await timeout(100)
      }
    }
    if (scrollRef.current) {
      scrollRef.current.style.overflow = 'auto'
    }
  }

  const { data: reportData } = useHouseReport(agentId)
  const { mutateAsync } = useRegenerateReportQuestion()
  const questionList = reportData ?? []
  const toaster = useToaster()

  if (data?.agent_type_id !== 9) {
    return (
      <div ref={scrollRef} className="w-full h-full overflow-auto">
        <div className="flex justify-center pt-[156px] pb-[100px]">
          <Navbar />
          <div className="flex flex-col items-center w-[1024px] pb-[100px]">
            <div className="flex items-center justify-between mb-[60px] w-full px-6 py-4">
              {data && (
                <div className="text-[40px] font-bold">{`Test Voice Agent (${data?.name})`}</div>
              )}
            </div>
            <div className="flex flex-col gap-6 w-full">
              {chatHistory.map((message: any) => {
                if (message.role !== 'human' && message.role != 'ai') {
                  return null
                }
                if (message.role === 'ai') {
                  return (
                    <div className="flex items-start w-full gap-3">
                      <img src={agentAvatar} />
                      <div className="px-3 py-2 rounded-2xl text-base max-w-[750px] bg-gray-50 conversation">
                        {message.content ? (
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                              a: LinkRenderer,
                            }}
                          >
                            {message.content}
                          </ReactMarkdown>
                        ) : (
                          <SyncLoader size={8} />
                        )}
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div className="flex items-start w-full gap-3 flex-row-reverse">
                      <img src={customerAvatar} />
                      <div className="px-3 py-2 rounded-2xl text-base max-w-[750px] bg-gray-50 conversation">
                        {message.content ? (
                          <ReactMarkdown
                            components={{
                              a: LinkRenderer,
                            }}
                          >
                            {message.content}
                          </ReactMarkdown>
                        ) : (
                          <SyncLoader size={8} />
                        )}
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
          <div className="fixed flex items-center justify-center w-full py-6 bottom-0 gap-4 border-t border-t-gray-200 bg-white px-[200px]">
            <div className="grow">
              <TextArea
                rows={1}
                height={24}
                value={instruction}
                isError={false}
                onChange={(e) => {
                  setInstruction((e.target as HTMLInputElement).value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleSubmit()
                  }
                }}
                fixHeight={false}
                maxHeight={96}
              />
            </div>
            <Button
              type={ButtonType.PRIMARY}
              content="Send"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="flex w-full h-screen">
        <Navbar />
        <div className="w-2/3 h-full overflow-scroll border-r border-solid">
          <div className="flex flex-col gap-8 p-10 pt-[120px]">
            {questionList?.map((question: Question, index: number) => {
              if (question.have_future_question) {
                return null
              }
              if (question.is_generating) {
                return (
                  <div className="h-[76px] flex items-center justify-center">
                    <MoonLoader size={16} />
                  </div>
                )
              } else {
                return (
                  <div className="flex flex-col gap-3">
                    <div className="flex justify-between">
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: LinkRenderer,
                        }}
                      >
                        {question.title_name}
                      </ReactMarkdown>
                      <div className="flex items-center gap-5">
                        {index === 0 ? (
                          <Button
                            type={ButtonType.SECONDARY}
                            content="Share report"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                encodeURI(`https://servicegenie.ai/public-report/${token}?name=${data?.name}&message=${data?.open_message}`)
                              )
                              toaster.push(<Message type='info'>
                                Sharable link copied to clipboard.
                              </Message>, { placement: 'topCenter', duration: 2000 })
                            }}
                          />
                        ) : null}
                        {question.query !== '' ? (
                          <Button
                            type={ButtonType.SECONDARY}
                            content="Regenerate"
                            loading={question.is_generating}
                            onClick={async () => {
                              queryClient.setQueryData(
                                ['house_report', agentId],
                                (data: any) => {
                                  const res = data.map((q: Question) => {
                                    if (q.id === question.id) {
                                      return {
                                        ...q,
                                        is_generating: true,
                                      }
                                    } else {
                                      return q
                                    }
                                  })
                                  return res
                                },
                              )
                              await mutateAsync({
                                agentId: agentId ?? '',
                                id: question.id,
                              })
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                    {question.query !== '' ? (
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: LinkRenderer,
                        }}
                      >
                        {question.answer}
                      </ReactMarkdown>
                    ) : (
                      <></>
                    )}
                  </div>
                )
              }
            })}
          </div>
        </div>
        <div ref={scrollRef} className="w-1/3 h-full overflow-scroll">
          <div className="flex pt-[120px] items-center justify-between mb-5 w-full px-10">
            {data && (
              <div className="text-[20px] font-bold">{`Test Voice Agent (${data?.name})`}</div>
            )}
          </div>
          <div className="flex flex-col gap-6 w-full pt-0 pb-[150px] p-10">
            {chatHistory.map((message: any) => {
              if (message.role !== 'human' && message.role != 'ai') {
                return null
              }
              if (message.role === 'ai') {
                return (
                  <div className="flex items-start w-full gap-3">
                    <img src={agentAvatar} />
                    <div className="px-3 py-2 rounded-2xl text-base max-w-[750px] bg-gray-50 conversation">
                      {message.content ? (
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            a: LinkRenderer,
                          }}
                        >
                          {message.content}
                        </ReactMarkdown>
                      ) : (
                        <SyncLoader size={8} />
                      )}
                    </div>
                  </div>
                )
              } else {
                return (
                  <div className="flex items-start w-full gap-3 flex-row-reverse">
                    <img src={customerAvatar} />
                    <div className="px-3 py-2 rounded-2xl text-base max-w-[750px] bg-gray-50 conversation">
                      {message.content ? (
                        <ReactMarkdown
                          components={{
                            a: LinkRenderer,
                          }}
                        >
                          {message.content}
                        </ReactMarkdown>
                      ) : (
                        <SyncLoader size={8} />
                      )}
                    </div>
                  </div>
                )
              }
            })}
          </div>
          <div className="fixed flex items-center justify-center w-1/3 py-6 px-10 bottom-0 gap-4 border-t border-t-gray-200 bg-white">
            <div className="grow">
              <TextArea
                rows={1}
                height={24}
                value={instruction}
                isError={false}
                onChange={(e) => {
                  setInstruction((e.target as HTMLInputElement).value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleSubmit()
                  }
                }}
                fixHeight={false}
                maxHeight={96}
              />
            </div>
            <Button
              type={ButtonType.PRIMARY}
              content="Send"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div >
    )
  }
}

export default Test
