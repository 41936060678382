import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

type Props = { isError: boolean; errMsg?: string; height?: number; fixHeight?: boolean; maxHeight?: number } & HTMLProps<HTMLTextAreaElement>;

const TextArea = ({
  isError,
  errMsg,
  placeholder,
  value,
  onFocus,
  onBlur,
  className,
  onChange,
  height,
  fixHeight = true,
  maxHeight,
  ...props
}: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    if (!fixHeight && ref.current) {
      ref.current.style.height = 'auto'
      ref.current.style.height = `${ref.current.scrollHeight}px`
    }
  }, [value])
  return (
    <div
      className={classnames(
        'bg-gray-50 rounded-lg h-fit p-4',
        {
          'ug-input-ctn--focused': true,
        },
        className,
      )}
    >
      <textarea
        ref={ref}
        className={classnames("border-none outline-none w-full bg-transparent text-base resize-none overflow-y-scroll")}
        style={fixHeight ? { height: height ?? 164, maxHeight } : { maxHeight }}
        placeholder={placeholder}
        onChange={e => {
          if (!fixHeight && ref.current) {
            ref.current.style.height = 'auto'
            ref.current.style.height = `${ref.current.scrollHeight}px`
          }
          onChange?.(e);
        }}
        value={value}
        {...props}
      />
    </div>
  );
};

export default TextArea;
