import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

type Props = { isError: boolean; errMsg?: string } & HTMLProps<HTMLInputElement>;

const Input = ({
  isError,
  errMsg,
  placeholder,
  value,
  onFocus,
  onBlur,
  className,
  onChange,
  ...props
}: Props) => {

  return (
    <div
      className={classnames(
        'bg-gray-50 rounded-lg h-[54px] p-4',
        {
          'ug-input-ctn--focused': true,
        },
        className,
      )}
    >
      <input
        className="border-none outline-none h-full w-full bg-transparent text-base"
        placeholder={placeholder}
        onChange={e => {
          onChange?.(e);
        }}
        value={value}
        {...props}
      />
    </div>
  );
};

export default Input;
