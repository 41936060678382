import React from 'react';
import { MoonLoader } from 'react-spinners';

export enum ButtonType {
  PRIMARY,
  SECONDARY,
  SELECTED
}

type Props = {
  type: ButtonType,
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  content: string,
  disabled?: boolean,
  loading?: boolean,
  onClick: () => void,
}

function Button(props: Props) {
  const { Icon, type, content, onClick, disabled, loading } = props
  if (loading) {
    return <div className='bg-gray-200 h-10 min-w-24 rounded-lg px-4 py-2 flex gap-1 items-center cursor-not-allowed w-fit justify-center'>
      {
        Icon && <Icon className='fill-white' />
      }
      <MoonLoader size={16} />
      <div className='text-gray-500'>{content}</div>
    </div>
  }
  if (disabled) {
    return <div className='bg-gray-200 h-10 min-w-24 rounded-lg px-4 py-2 flex gap-1 items-center cursor-not-allowed w-fit justify-center'>
      {
        Icon && <Icon className='fill-white' />
      }
      <div className='text-base font-semibold text-white'>{content}</div>
    </div>
  }
  switch (type) {
    case ButtonType.PRIMARY:
      return (
        <div className='bg-blue-500 hover:bg-blue-600 h-10 min-w-24 rounded-lg px-4 py-2 flex gap-1 items-center cursor-pointer w-fit justify-center' onClick={onClick}>
          {
            Icon && <Icon className='fill-white' />
          }
          <div className='text-base font-semibold text-white'>{content}</div>
        </div>
      );
    case ButtonType.SECONDARY:
      return (
        <div className='bg-white hover:bg-blue-50 h-10 min-w-24 rounded-lg px-4 py-2 flex gap-1 items-center border-blue-500 border-solid border cursor-pointer w-fit justify-center' onClick={onClick}>
          {
            Icon && <Icon className='fill-blue-500' />
          }
          <div className='text-base font-semibold text-blue-500'>{content}</div>
        </div>
      );
    case ButtonType.SELECTED:
      return (
        <div className='bg-blue-50 hover:bg-blue-50 h-10 min-w-24 rounded-lg px-4 py-2 flex gap-1 items-center border-blue-500 border-solid border cursor-not-allowed w-fit justify-center'>
          {
            Icon && <Icon className='fill-blue-500' />
          }
          <div className='text-base font-semibold text-blue-500'>{content}</div>
        </div>
      );
    default:
      return (
        <div className='bg-blue-500 hover:bg-blue-600 h-10 min-w-24 rounded-lg px-4 py-2 flex gap-1 items-center cursor-pointer w-fit justify-center' onClick={onClick}>
          {
            Icon && <Icon className='fill-white' />
          }
          <div className='text-base font-semibold text-white'>{content}</div>
        </div>
      );

  }

}

export default Button;
