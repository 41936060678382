import { useMutation } from '@tanstack/react-query'
import useAuthStore from '../store/auth'
import { axiosWithAuth } from '../utils/axios'

interface RegisterRequest {
  email: string
  password: string
}

export const useRegister = () => {
  const axios = axiosWithAuth()
  const { setLoading, setSuccessModalOpen } = useAuthStore()
  const register = async ({ email, password }: RegisterRequest) => {
    setLoading(true)
    try {
      const res = await axios.post('auth/register', {
        email,
        password,
      })
      if (res.data.code === 200) {
        setSuccessModalOpen(true)
      }
      return res
    } catch (e) {
      return null
    } finally {
      setLoading(false)
    }
  }

  const { mutate } = useMutation(register)
  return { mutate }
}
