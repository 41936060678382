import React, { useEffect, useState } from 'react';
import Button, { ButtonType } from '../components/Button';
import { useAgents } from '../query/useAgents';
import { ReactComponent as Add } from '../static/Add.svg';
import AgentList from '../components/AgentList';
import { MoonLoader } from 'react-spinners';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useQuery } from '../utils/query';


function Home() {
  const { data, isFetching, isLoading, isFetched } = useAgents()
  const navigate = useNavigate()
  const query = useQuery()
  useEffect(() => {
    if (query.get('first_screen') === '1' && isFetched && data.length === 0) {
      navigate('/create')
    }
  }, [
    isFetched, data
  ])
  return (
    <div className='flex justify-center pt-[156px] pb-[100px]'>
      <Navbar />
      <div className='flex flex-col items-center w-[1024px]'>
        <div className='flex items-center justify-between mb-[60px] w-full px-6 py-4'>
          <div className='text-[40px] font-bold'>All voice agents</div>
        </div>
        {isFetching || isLoading ? <div className='flex min-h-[200px] items-center'><MoonLoader size={24} /></div> : <AgentList agents={data} />}
      </div>
    </div>
  );
}

export default Home;
